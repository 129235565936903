export default {
    en: {
        LANDING_SLOGAN: 'Develop your local, online and lively market place',
        LANDING_GETSTARTED: 'GET STARTED',
        LANDING_GETDEMO: 'GET DEMO',
        LANDING_QUESTION: 'What makes your city dynamic and pleasant to live in?',
        LANDING_DESCRIPTION: 'You are in charge of a territory and want to make it active and lively. Here is how we envision this transformation:',
        LANDING_LOCALSHOP: 'Active local shops and service providers',
        LANDING_RESIDENTS: 'Residents who participate in the life of the neighborhood',
        LANDING_PUBLIC_SERVICES: 'Public and private services in high demand',
        LANDING_ENVIRONMENT: 'In a soothing and gentle environment',
        LANDING_FAITHFUL_RESIDENTS: 'Faithful residents (and who vote)',
        LANDING_REVENUE: 'Revenue for the municipality',
        LANDING_PROCESS: 'Our Process',
        LANDING_PROCESS_DESCRIPTION: 'We support you on all the following stages:',
        LANDING_STEP1: 'Step 1',
        LANDING_STEP1_DESCRIPTION: 'Mapping of the territory\'s service providers',
        LANDING_STEP2: 'Step 2',
        LANDING_STEP2_DESCRIPTION: 'Interviews, videos, photos of local providers',
        LANDING_STEP3: 'Step 3',
        LANDING_STEP3_DESCRIPTION: 'Online visibility of the providers',
        LANDING_STEP4: 'Step 4',
        LANDING_STEP4_DESCRIPTION: 'Access by residents via the Hoa Ora App',
        LANDING_WHYUS: 'Why us?',
        LANDING_OURTEAM: 'Our Team:',
        LANDING_MARIANE1: 'CEO, Biology and IT engineer',
        LANDING_MARIANE2: ' ex Sanofi and ASIP-santé (now ANS: French Digital Health Agency) ',
        LANDING_MARIANE3: 'Digital health representative of Syntec Numérique (professional organisation: 300 healthcare companies/2 000 IT companies)',
        LANDING_ALAIN1: 'R&D, Doctor of Science',
        LANDING_ALAIN2: 'Scientific IT development companies,',
        LANDING_ALAIN3: 'ex Inserm-Transfert, CEO of Cimbiose',
        LANDING_MARCO1: 'Commercial, Sociologist',
        LANDING_MARCO2: 'Sale of services for communities',
        LANDING_EXPERIENCE: 'Our Experience:',
        LANDING_EXPERIENCE_TEXT1: 'We know the problems of local authorities. Our professional network brings together many solutions from all sectors of activity, including health. We have a long history of managing collaborative and complex projects. Finally, we are driven by the desire to provide the best solutions to our most fragile citizens.',
        LANDING_EXPERIENCE_TEXT2: 'The company is labelled by the French Ministry of Economy for structures with social and environmental impacts (ESUS).',
        LANDING_CONTACTUS: 'Contact Us',
        LANDING_CONTACTUS_TEXT: 'Contact our support team if you have any query in mind. We are always here to help you.',
        LANDING_EMAIL: 'Email',
        LANDING_PHONE: 'Phone',
        LANDING_ADDRESS: 'Address',
        LANDING_NAME: 'Name',
        LANDING_WEBSITE: 'Website',
        LANDING_MESSAGE_PLACEHOLDER: 'Enter your message',
        LANDING_SEND_MESSAGE: 'SEND MESSAGE'

    },
    fr: {
        LANDING_SLOGAN: 'Développez votre place de marché locale, en ligne et animée',
        LANDING_GETSTARTED: 'Démarrer',
        LANDING_GETDEMO: 'Obtenir une démo',
        LANDING_QUESTION: 'Qu\'est-ce qui rend votre ville dynamique et agréable à vivre ?',
        LANDING_DESCRIPTION: 'Vous êtes en charge d\'un territoire et souhaitez le rendre actif et vivant. Voici comment nous envisageons cette transformation :',
        LANDING_LOCALSHOP: 'Des commerces et fournisseurs de services de proximité actifs',
        LANDING_RESIDENTS: 'Des habitants qui participent à la vie du quartier',
        LANDING_PUBLIC_SERVICES: 'Des services publics et privés très sollicités',
        LANDING_ENVIRONMENT: 'Dans un environnement apaisant et doux',
        LANDING_FAITHFUL_RESIDENTS: 'Des habitants fidèles (et qui votent)',
        LANDING_REVENUE: 'Des recettes pour la municipalité',
        LANDING_PROCESS: 'Notre processus',
        LANDING_PROCESS_DESCRIPTION: 'Nous vous accompagnons sur toutes les étapes suivantes:',
        LANDING_STEP1: 'Étape 1',
        LANDING_STEP1_DESCRIPTION: 'Cartographie du territoire (une méthode et un livrable clair)',
        LANDING_STEP2: 'Étape2',
        LANDING_STEP2_DESCRIPTION: 'Entretiens, vidéos, photos de prestataires locaux',
        LANDING_STEP3: 'Étape 3',
        LANDING_STEP3_DESCRIPTION: 'Visibilité en ligne des fournisseurs',
        LANDING_STEP4: 'Étape 4',
        LANDING_STEP4_DESCRIPTION: 'Accès des citoyens via l\'application Hoa Ora',
        LANDING_WHYUS: 'Pourquoi nous ?',
        LANDING_OURTEAM: 'Notre équipe :',
        LANDING_MARIANE1: 'PDG, Ingénieur biologie et informatique',
        LANDING_MARIANE2: 'ex Sanofi et ASIP-santé (devenue Agence du Numérique en Santé)',
        LANDING_MARIANE3: 'Déléguée au numérique en santé Syntec Numérique (300 entreprises esanté/2 000 sociétés)',
        LANDING_ALAIN1: 'R&D, Docteur es sciences',
        LANDING_ALAIN2: 'Sociétés de développement en informatique scientifique',
        LANDING_ALAIN3: 'ex Inserm-Transfert, PDG de Cimbiose',
        LANDING_MARCO1: 'Commercial, Sociologue',
        LANDING_MARCO2: 'Vente de services pour collectivités',
        LANDING_EXPERIENCE: 'Notre expérience',
        LANDING_EXPERIENCE_TEXT1: 'Nous connaissons les problèmes des collectivités locales. Notre réseau professionnel regroupe de nombreuses solutions issues de tous les secteurs d\'activité, dont la santé. Nous avons une longue expérience de gestion de projets collaboratifs et complexes. Enfin, nous sommes animés par la volonté d\'apporter les meilleures solutions à nos citoyens les plus fragiles.',
        LANDING_EXPERIENCE_TEXT2: 'L\'entreprise est labellisée par le ministère de l\'Économie pour les structures à impacts sociaux et environnementaux (ESUS).',
        LANDING_CONTACTUS: 'Nous contacter',
        LANDING_CONTACTUS_TEXT: 'Contactez notre équipe d\'assistance si vous avez des questions à l\'esprit. Nous sommes toujours là pour vous aider.',
        LANDING_EMAIL: 'Email',
        LANDING_PHONE: 'Téléphone',
        LANDING_ADDRESS: 'Adresse',
        LANDING_NAME: 'Nom',
        LANDING_WEBSITE: 'Site web',
        LANDING_MESSAGE_PLACEHOLDER: 'Votre message',
        LANDING_SEND_MESSAGE: 'Envoyer'

    }
};
