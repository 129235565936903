/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.Eloquent;
export const AXIOS = window._axios;
export const MobileDetect = window.MobileDetect;

// see https://github.com/SchwSimon/creatella-react-components#withasynccaller
window._withAsyncCallerAxios = window._BaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_MAPS_API_KEY = 'AIzaSyAaYm3tt-uWuwF9zj9wcH793K7R6jcnLsc';

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';

export const STORAGEKEY_AXYN = 'sk-axyn';
export const STORAGEKEY_AXYN_USER_EMAIL = 'sk-axynUserEmail';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'fr';

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: ''
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Roboto:300',
            'Roboto:400',
            'Roboto:500',
            'Roboto:700',
            'Roboto:900'
        ]
    }
};

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'TODO_REPLACE',
    INSTAGRAM: 'TODO_REPLACE',
    TWITTER: 'TODO_REPLACE',
    LINKEDIN: 'TODO_REPLACE'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    ABOUT: '/about',
    CONTACT: '/contact',

    LOGIN: '/login',
    REGISTER: '/register',
    PROFILE: '/profile',

    SERVICES: '/ratonga',
    SERVICES_BY_CITY: (cityName = ':cityName') => `/ratonga/${cityName}`,

    AXYN_SERVICES: '/axyn',
    AXYN_SERVICES_BY_CITY: (cityName = ':cityName') => `/axyn/${cityName}`,
    AXYN_WITH_EMAIL: (email = ':email') => `/axyn/email/${email}`,
    AXYN_WITH_EMAIL_CITY: (email = ':email', cityName = ':cityName') => `/axyn/${cityName}/email/${email}`,

    SERVICE: (id = ':id') => `/service/${id}`,
    ADD_SERVICE: '/add-service',
    EDIT_SERVICE: (id = ':id') => `/edit-service/${id}`,

    AXYN: '/axyn',

    _TESTING: '/testing'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.SERVICES;
export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.SERVICES;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.SERVICES;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.SERVICES;

/****
 *
 * Project config
 *
 */

export const CATEGORY_NAMES = {
    'My Health': 'My Health',
    'My Practical Life': 'My Practical Life',
    'My Social Life': 'My Social Life',
    'My House': 'My House',
    Others: 'Others'
};

export const CATEGORIES = [
];

export const SUB_CATEGORIES = [
];

export const DELIVERY_METHOD_TYPES = {
    MOVE: 1,
    HOME_SERVICE: 2,
    CLICK_AND_COLLECT: 3
};

export const TIMES = getTimes();

export const MEDIA_TYPES = {
    DOCUMENT: 1,
    IMAGE: 2,
    VIDEO: 3,
    OTHERS: 4
};

export const PROFESSIONS = [
    { id: 1, name: 'Profession 1' },
    { id: 2, name: 'Profession 2' }
];

export const SERVICES = [
    { id: 1, name: 'Service 1' },
    { id: 2, name: 'Service 2' }
];

export const DELIVERY_METHODS = [
    { id: DELIVERY_METHOD_TYPES.MOVE, name: 'SERVICES_HOME_SERVICE' },
    { id: DELIVERY_METHOD_TYPES.HOME_SERVICE, name: 'SERVICES_COLLECT' }
    // { id: DELIVERY_METHOD_TYPES.CLICK_AND_COLLECT, name: 'SERVICES_DETECTION' }
];

/****
 *
 * Local function
 *
 */

function getTimes() {
    let counter = 0;

    return [...Array(24)].reduce((result) => {
        counter++;

        const sign = counter < 12 ? 'am' : 'pm';

        result.push({ id: counter, value: counter - 1, name: `${counter} ${sign}` });

        return result;
    }, []);
}

export const termsAndConditions = {
    fr: `En téléchargeant ou en utilisant l&#39;application, ces conditions s&#39;appliqueront automatiquement à vous - vous devez donc vous assurer de les lire attentivement avant d&#39;utiliser l&#39;application. Vous n&#39;êtes pas autorisé à copier ou modifier l&#39;application, toute partie de l&#39;application ou nos marques déposées de quelque manière que ce soit. Vous n&#39;êtes pas autorisé à tenter d&#39;extraire le code source de l&#39;application, et vous ne devriez pas non plus essayer de traduire l&#39;application dans d&#39;autres langues, ou de faire des versions dérivées. L&#39;application elle-même, ainsi que toutes les marques, droits d&#39;auteur, droits de base de données et autres droits de propriété intellectuelle qui s&#39;y rapportent, appartiennent à Hoa Ora.

    Hoa Ora s&#39;engage à faire en sorte que l&#39;application soit aussi utile et efficace que possible. Pour cette raison, nous nous réservons le droit d&#39;apporter des modifications à l&#39;application ou de facturer ses services, à tout moment et pour toute raison. Nous ne vous facturerons jamais l&#39;application ou ses services sans que vous sachiez exactement ce que vous payez.
    
    L&#39;application Hoa Ora stocke et traite les données personnelles que vous nous avez fournies, afin de fournir notre Service.
    
    Il est de votre responsabilité de garder votre dispositif (tablette, téléphone…) et vos codes d&#39;accès à l&#39;application en sécurité. Nous vous recommandons donc de ne pas jailbreaker ou rooter votre téléphone, qui est le processus de suppression des restrictions logicielles et des limitations imposées par le système d&#39;exploitation officiel de votre appareil. Cela pourrait rendre votre téléphone vulnérable aux logiciels malveillants/virus/ programmes malveillants, compromettre les fonctions de sécurité de votre téléphone et cela pourrait signifier que l&#39;application Hoa Ora ne fonctionnera pas correctement ou pas du tout et que nous ne pourrions garantir la sécurité de vos données.
    
    Sachez qu&#39;il y a certains aspects dont Hoa Ora n&#39;assume pas la responsabilité. Certaines fonctions de l&#39;application nécessitent une connexion Internet active. L&#39;application Hoa Ora ne peut fonctionner pleinement si vous n&#39;avez pas d&#39;accès à internet ou si Hoa Ora n&#39;a pas accès à vos données.
    
    Si vous utilisez l&#39;application en dehors d&#39;une zone avec Wi-Fi, vous devez vous rappeler que les termes de l&#39;accord avec votre fournisseur de réseau mobile s&#39;appliquent toujours. Par conséquent, votre fournisseur de services mobiles peut vous facturer le coût des données pour la durée de la connexion pendant laquelle vous accédez à l&#39;application, ou d&#39;autres frais de tierce partie. En utilisant l&#39;application, vous acceptez la responsabilité de ces frais, y compris les frais de données en itinérance si vous utilisez l&#39;application à l&#39;extérieur de votre territoire national (c&#39;est-à-dire dans votre région ou pays) sans désactiver l&#39;itinérance des données. Si vous n&#39;êtes pas le payeur de factures pour l&#39;appareil sur lequel vous utilisez l&#39;application, sachez que nous supposons que vous avez reçu la permission du payeur de factures pour utiliser l&#39;application.
    
    De la même façon, Hoa Ora ne peut pas assumer la responsabilité de la façon dont vous utilisez l&#39;application, notamment vous devez vous assurer que votre appareil reste chargé - si la batterie est épuisée et que vous ne pouvez pas l&#39;allumer pour utiliser le Service, Hoa Ora ne peut en assumer la responsabilité.
    
    En ce qui concerne la responsabilité de Hoa Ora quant à votre utilisation de l&#39;application, lorsque vous l&#39;utilisez, il est important de garder à l&#39;esprit que même si nous nous efforçons de nous assurer qu&#39;elle est fonctionnelle et à jour à tout moment, nous comptons sur des tiers pour nous fournir des informations afin que nous puissions vous les rendre disponibles. Hoa Ora décline toute responsabilité pour toute perte, directe ou indirecte, que vous pourriez subir en vous fiant entièrement à cette fonctionnalité de l&#39;application.
    
    L&#39;application est actuellement disponible sur Android et iOS - les exigences pour les deux systèmes (et pour tout autre système sur lequel nous décidons d&#39;étendre la disponibilité de l&#39;application) peuvent changer, et vous devrez télécharger les mises à jour de ces système pour assurer un fonctionnement adéquat de l&#39;application Hoa Ora. Hoa Ora ne promet pas de toujours mettre à jour l&#39;application pour qu&#39;elle soit pertinente pour vous et/ou fonctionne avec la version du système d&#39;exploitation que vous avez installée sur votre appareil.
    
    A tout moment, nous pouvons être amenés à mettre à jour l&#39;application. Vous vous engagez à toujours accepter les mises à jour de l&#39;application lorsqu&#39;elles vous sont offertes. Il se peut également que nous voulions cesser de fournir l&#39;application ou nous pouvons mettre fin à son utilisation à tout moment sans vous en aviser. Sauf indication contraire de notre part, en cas de résiliation, (a) les droits et licences qui vous sont accordés dans ces conditions prendront fin ; (b) vous devez cesser d&#39;utiliser l&#39;application, et (si nécessaire) la supprimer de votre appareil.
    
    **Modifications apportées aux présentes conditions d&#39;utilisation**
    
    Nous pouvons mettre à jour nos conditions générales de temps à autre. Par conséquent, nous vous conseillons de consulter cette page périodiquement pour vous tenir au courant de tout changement. Toute modification importante des conditions générales sera signalée sur l&#39;application et vous redirigera vers cette page. Ces modifications entrent en vigueur immédiatement après leur affichage sur cette page.
    
    **Nous Contacter**
    
    Si vous avez des questions ou des suggestions au sujet de nos conditions générales, n&#39;hésitez pas à nous contacter [contact@hoa-ora.com](mailto:contact@hoa-ora.com)`,
    en: `By downloading or using the application, these terms and conditions will automatically apply to you - so be sure to read them carefully before using the application. You&#39;re not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You are not allowed to attempt to extract the source code from the application, nor should you attempt to translate the application into other languages, or make derivative versions. The application itself, as well as all trademarks, copyrights, database rights and other intellectual property rights related thereto, belong to Hoa Ora.

    Hoa Ora is committed to ensuring the application is as useful and efficient as possible. For this reason, we reserve the right to make changes to the application or to charge for its services, at any time and for any reason. We will never charge you for the application or its services without you knowing exactly what you are paying for.
    
    The Hoa Ora application stores and processes the personal data you have provided to us, in order to provide our Service.
    
    It is your responsibility to keep your device (tablet, phone...) and your access codes to the application secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. This could make your phone vulnerable to malware/virus/malicious programs, and compromise the security of your phone . it could mean that the Hoa Ora application will not work properly or not at all and that we cannot guarantee the security of your data.
    
    You should know that there are some aspects for which Hoa Ora does not take responsibility. Some of the application&#39;s functions require an active Internet connection. The Hoa Ora application cannot fully work if you do not have internet access or if Hoa Ora does not have access to your data.
    
    If you use the application outside an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider still apply. As a result, your wireless service provider may charge you for the cost of data for the duration of the connection during which you access the application, or for other third-party fees. By using the application, you accept responsibility for these charges, including data roaming charges if you use the application outside your home territory (i.e. in your region or country) without disabling data roaming. If you are not the bill payer for the device on which you are using the application, please note that we assume that you have received permission from the bill payer to use the application.
    
    Similarly, Hoa Ora cannot assume responsibility for the way you use the application, including ensuring that your device remains charged - if the battery is exhausted and you cannot turn it on to use the Service, Hoa Ora cannot assume responsibility.
    
    With respect to Hoa Ora&#39;s responsibility for your use of the application, when you use it, it is important to keep in mind that while we strive to ensure that it is functional and up-to-date at all times, we rely on third parties to provide us with information so that we can make them available to you. Hoa Ora accepts no responsibility for any loss, direct or indirect, that you may incur as a result of your complete reliance on this feature of the application.
    
    The application is currently available on Android and iOS - the requirements for both systems (and for any other system on which we decide to extend the availability of the application) may change, and you will need to download updates from these systems to ensure proper operation of the Hoa Ora application. Hoa Ora does not promise to always update the application to make it relevant to you and/or work with the version of the operating system you have installed on your device.
    
    At any time, we may need to update the application. You agree to always accept updates to the application when they are available to you.
    
    We may also want to stop providing the application or we may terminate its use at any time without notice to you. Unless otherwise specified by us, in the event of termination, (a) the rights and licenses granted to you under these terms and conditions will terminate; (b) you must stop using the application, and (if necessary) remove it from your device.
    
    **Changes to these Terms of Use**
    
    We may update our terms and conditions from time to time. Therefore, we recommend that you check this page periodically to be aware of any changes. Any significant changes to the terms and conditions will be posted on the application and will redirect you to this page. These changes are effective immediately after they are posted on this page.
    
    **Contact Us**
    
    If you have any questions or suggestions about our terms and conditions, please do not hesitate to contact us at [contact@hoa-ora.com](mailto:contact@hoa-ora.com)`
};
