/* eslint-disable import/no-anonymous-default-export */
export default {
    en: {
        COMMON_SERVICES: 'Services',
        COMMON_ABOUTUS: 'About Us',
        COMMON_ADDYOURSERVICE: 'Add your Service',
        COMMON_CATEGORY: 'Category',
        COMMON_SUBCATEGORY: 'Sub category',
        COMMON_SUBSUBCATEGORY: 'Sub-sub category',
        COMMON_FIRSTNAME: 'First Name',
        COMMON_MIDDLENAME: 'Middle Name',
        COMMON_LASTNAME: 'Last Name',
        COMMON_MESSAGESENT: 'Your message was sent successfully!',
        COMMON_MESSAGEFAILED: 'Error! Your message was not sent successfully!',
        COMMON_ERROR: 'Error! Please try again.',
        COMMON_REQUIREDERROR: 'Error! Please fill all the required fields.',
        COMMON_FILEERROR: 'Error! Please select a valid excel file.'
    },
    fr: {
        COMMON_FILEERROR: 'Erreur! Veuillez sélectionner un fichier Excel valide.',
        COMMON_SERVICES: 'Services',
        COMMON_ABOUTUS: 'Qui sommes nous ?',
        COMMON_ADDYOURSERVICE: 'Ajouter votre service',
        COMMON_CATEGORY: 'Catégorie',
        COMMON_SUBCATEGORY: 'Sous Catégorie',
        COMMON_SUBSUBCATEGORY: 'Sous-Sous Catégorie',
        COMMON_FIRSTNAME: 'Prénom',
        COMMON_MIDDLENAME: 'Deuxième nom',
        COMMON_LASTNAME: 'Nom de famille',
        COMMON_MESSAGESENT: 'vérifier si l\'envoi du message a réussi. ',
        COMMON_MESSAGEFAILED: 'Erreur! Votre message n\'a pas été envoyé.!',
        COMMON_ERROR: 'Erreur! Veuillez réessayer.',
        COMMON_REQUIREDERROR: 'Erreur! Veuillez sélectionner un fichier Excel valide.'
    }
};
