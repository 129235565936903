import { ELOQUENT } from 'config/constants';

/**
 * Upload image
 * @param {Object} file
 * @returns {Promise}
 */
export function uploadImage(file) {
    return ELOQUENT.Upload.image(file);
}

/**
 * Upload file
 * @param {Object} file
 * @returns {Promise}
 */
export function uploadFile(file) {
    return ELOQUENT.Upload.file(file);
}
