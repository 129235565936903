import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import InputGroup from 'creatella-react-components/lib/InputGroup';
import Button from 'creatella-react-components/lib/Button';
import Avatar from 'creatella-react-components/lib/Avatar';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import { uploadImage } from 'api/upload';
import './ImageUpload.scss';
// Assets
import ICON_UPLOAD from 'assets/services/icon-upload.svg';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';

function ImageUpload(props) {
    const { isSmall, isInvalid, name, label, apiCaller, onChange, value, type } = props;
    const fileRef = useRef();
    const [isProcessing, setIsProcessing] = useState(false);
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (value) {
            setSrc(value);
        }
    }, [value]);

    const onClickUploader = async (e) => {
        fileRef.current.click();
    };

    const onChangeFile = async (e) => {
        const file = e.target.files[0];

        setIsProcessing(true);

        try {
            const { data: filePath } = await apiCaller(uploadImage, file);

            setSrc(filePath);
            setIsProcessing(false);
            onChange && onChange(name, filePath);
        } catch (err) {
            setIsProcessing(false);
        }
    };

    const onDelete = async (e) => {
        e.stopPropagation();
        setSrc(null);
        onChange && onChange(name, undefined);
    };

    return (
        <InputGroup
            className={`ImageUpload ${isSmall ? 'ImageUpload--small' : ''} ${isInvalid ? 'ImageUpload--invalid' : ''}`}
            onClick={onClickUploader}>
            <input
                className='ImageUpload__input'
                ref={fileRef}
                type='file'
                accept={type}
                onChange={onChangeFile} />

            <div
                className='ImageUpload__uploader'>
                {isProcessing
                    ? <ActivityIndicator size={30} />
                    : <>
                        <img
                            className='ImageUpload__uploader-icon'
                            src={ICON_UPLOAD}
                            alt='' />
                        <div className='ImageUpload__uploader-text'>
                            {label}
                        </div>
                    </>
                }
            </div>

            {src && (
                <div className='ImageUpload__preview'>
                    <Avatar
                        className='ImageUpload__preview-Avatar'
                        src={src} />

                    <Button
                        className='ImageUpload__preview-remove'
                        onClick={onDelete}>
                        <FontAwesomeIcon
                            icon={faTimesCircle} />
                    </Button>
                </div>
            )}
        </InputGroup>
    );
}

ImageUpload.propTypes = {
    isSmall: PropTypes.bool,
    isInvalid: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    apiCaller: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.object,
    type: PropTypes.string
};

ImageUpload.defaultProps = {
    label: 'Tap to upload Image/Logo',
    type: 'image/*'
};

export default withAsyncCaller(ImageUpload);
