/* eslint-disable import/no-anonymous-default-export */
export default {
    en: {
        DETAILS_SERVICE: 'Service details',
        DETAILS_CONTACT: 'Contact',
        DETAILS_ABOUT: 'About',
        DETAILS_TIMING: 'Timing',
        DETAILS_TIMING_OPEN_NOW: 'Open Now',
        DETAILS_TIMING_CLOSED_NOW: 'Closed Now',
        DETAILS_TIMING_BREAK: 'Break',
        DETAILS_TIMING_HOLIDAY: 'Holiday',
        DETAILS_PHOTOS: 'Photos',
        DETAILS_VIDEOS: 'Videos',
        DETAILS_DOCS: 'Documents',
        PROMPT_MESSAGE: 'An email has been sent to administrator Hoa Ora to inform him of your request'
    },
    fr: {
        PROMPT_MESSAGE: 'Un email a été envoyé à l\'administrateur Hoa Ora pour lui faire fart de votre demande',
        DETAILS_SERVICE: 'Détails du service',
        DETAILS_CONTACT: 'Contacter',
        DETAILS_ABOUT: 'Sur',
        DETAILS_TIMING: 'Heures d\'ouverture',
        DETAILS_TIMING_OPEN_NOW: 'Ouvrez maintenant',
        DETAILS_TIMING_CLOSED_NOW: 'Fermé maintenant',
        DETAILS_TIMING_BREAK: 'Pause',
        DETAILS_TIMING_HOLIDAY: 'Vacances',
        DETAILS_PHOTOS: 'Images',
        DETAILS_VIDEOS: 'Vidéos',
        DETAILS_DOCS: 'Documents'

    }
};
