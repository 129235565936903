/* eslint-disable import/no-anonymous-default-export */
export default {
    en: {
        SERVICES_NO_SERVICE: 'No service available',
        SERVICES_FILTERS: 'FILTERS',
        SERVICES_ADDRESS: 'Search Address',
        SERVICES_ADDRESS_PLACEHOLDER: 'Enter an address',
        SERVICES_LOCATEME: 'Locate me',
        SERVICES_HOME: 'At home',
        SERVICES_RESEARCHING: 'What are you searching for ?',
        SERVICES_RESEARCHING_PLACEHOLDER: 'What are you searching for ?',
        SERVICES_CATEGORIES: 'Categories',
        SERVICES_SUB_CATEGORIES: 'Sub-categories',
        SERVICES_SUB_SUB_CATEGORIES: 'Sub-sub-categories',
        SERVICES_AVAILABILITIES: 'My availabilities',
        SERVICES_DELIVERY: 'Delivery method',
        SERVICES_MOVE: 'I move',
        SERVICES_HOME_SERVICE: 'Home service',
        SERVICES_COLLECT: 'Click and collect',
        SERVICES_DETECTION: 'Please allow location access',
        SERVICES_NO_DATA: 'Empty data',
        SERVICES_INVALIDADDRESS: 'Error, Invalid user address!',
        SERVICES_ZIPCODESEARCH: 'search by zipcode',
        SERVICES_ADDRESSSEARCH: 'search by address'
    },
    fr: {
        SERVICES_ADDRESSSEARCH: 'rechercher par adresse',
        SERVICES_ZIPCODESEARCH: 'rechercher par code postal',
        SERVICES_INVALIDADDRESS: 'Erreur, adresse utilisateur invalide !',
        SERVICES_NO_SERVICE: 'Aucun service disponible',
        SERVICES_FILTERS: 'FILTRES',
        SERVICES_ADDRESS: 'Adresse de recherche',
        SERVICES_ADDRESS_PLACEHOLDER: 'Taper un emplacement',
        SERVICES_LOCATEME: 'Me Localiser',
        SERVICES_HOME: 'Chez moi',
        SERVICES_RESEARCHING: 'Qu\'est-ce que vous recherchez ?',
        SERVICES_RESEARCHING_PLACEHOLDER: 'Qu\'est-ce que vous recherchez ?',
        SERVICES_CATEGORIES: 'Catégories',
        SERVICES_SUB_CATEGORIES: 'Sous-catégories',
        SERVICES_SUB_SUB_CATEGORIES: 'Sous-sous-catégories',
        SERVICES_AVAILABILITIES: 'Mes disponibilités',
        SERVICES_DELIVERY: 'Mode de livraison',
        SERVICES_MOVE: 'Je me déplace',
        SERVICES_HOME_SERVICE: 'Service à domicile',
        SERVICES_COLLECT: 'Cliquez et collectez',
        SERVICES_DETECTION: 'Veuillez autoriser l\'accès à l\'emplacement',
        SERVICES_NO_DATA: 'Données vides'

    }
};
