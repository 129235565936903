import { useEffect, useRef } from 'react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { STORAGEKEY_APP_LANGUAGE, APP_LANGUAGE_DEFAULT } from 'config/constants';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isURL from 'validator/lib/isURL';

/**
 * Get app language
 * @returns {String}
 */
export function getAppLanguage() {
    return localStorage.getItem(STORAGEKEY_APP_LANGUAGE) || APP_LANGUAGE_DEFAULT;
}

/**
 * Set app language
 * @param {String} language
 */
export function setAppLanguage(language) {
    localStorage.setItem(STORAGEKEY_APP_LANGUAGE, language);
}

/**
 * Validate email address
 * @param {String} email
 * @returns {Boolean}
 */
export function validateEmail(email) {
    return isEmail(email);
}

/**
 * Validate phone number
 * @param {String} phone
 * @returns {Boolean}
 */
export function validatePhoneNumber(phone) {
    return isMobilePhone(phone);
}

/**
 * Validate website url
 * @param {String} website
 * @returns {Boolean}
 */
export function validateWebSite(website) {
    return isURL(website);
}

/**
 * Convert text to title case
 * @param {String} text
 * @returns {String}
 */
export function toTitleCase(text) {
    return text.replace(
        /\w\S*/g,
        (text) => {
            return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
        }
    );
}

/**
 * Get Youtube thumbnail URL
 * @param {String} url
 * @returns {String}
 */
export function getYoutubeThumbnailUrl(url) {
    let youtubeId;

    const pattern = /v=(\w+)/;
    const matches = pattern.exec(url);
    const pattern2 = /\/\/youtu.be\/(\w+)/;
    const matches2 = pattern2.exec(url);

    if (matches && matches.length) {
        youtubeId = matches[1];
    } else if (matches2 && matches2.length) {
        youtubeId = matches2[1];
    }

    return youtubeId ? `https://img.youtube.com/vi/${youtubeId}/3.jpg` : undefined;
}

/**
 * useDebounce hooks
 * @param {Function} callback
 * @param {Number} timeout
 * @param {Array} deps
 */
export function useDebounce(callback, timeout, deps) {
    const timeoutId = useRef();

    useEffect(() => {
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(callback, timeout);

        return () => clearTimeout(timeoutId.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

/**
 * Convert time range to timing
 * @param {Array} timeRange
 * @returns {Object}
 */
export function convertTimeRange(timeRange) {
    let [openAt, closeAt] = timeRange;
    const result = {};

    if (openAt) {
        openAt = new Date(new Date(0).setHours(openAt));
        result.open_at = format(openAt, 'HH:mm:ss');
    }

    if (closeAt) {
        closeAt = new Date(new Date(0).setHours(closeAt));
        result.close_at = format(closeAt, 'HH:mm:ss');
    }

    return result;
}

/**
 * Convert timing to time range
 * @param {Object} timing
 * @returns {Array}
 */
export function convertTiming(timing) {
    let { open_at: openAt, close_at: closeAt } = timing;

    openAt = parse(openAt, 'HH:mm:ss', new Date()).getHours();
    closeAt = parse(closeAt, 'HH:mm:ss', new Date()).getHours();

    const result = [];

    openAt && result.push(openAt);
    closeAt && result.push(closeAt);

    return result;
}

/**
 * Get random coordinate inside circle
 * @param {Number} r1
 * @param {Number} r2
 * @returns {Objet}
 */
export function getRandomCoordinateInsideCircle(r1, r2 = 0) {
    if (r1 <= r2) {
        throw new Error('r1 must be > r2');
    }

    const angle = 360 * Math.random();
    const radius = r2 + (r1 - r2) * Math.random();

    return {
        x: Math.cos(angle) * radius,
        y: Math.sin(angle) * radius
    };
}

/**
 * Convert a zoned time into local time
 * @param {String} dateString Date string
 * @param {String} formatString Date string format
 * @param {String} tz Time zone of the source date
 * @returns {Date} Date object
 */
export function zonedTimeToLocal(dateString, formatString, tz) {
    if (!dateString) {
        return new Date(0);
    }

    const initTime = parse(dateString, formatString, new Date());
    const utcTime = zonedTimeToUtc(initTime, tz);
    const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return utcToZonedTime(utcTime, localTz);
}

/**
 * Format date in to time zone
 * @param {String | Number | Date} date
 * @param {String} formatString
 * @param {String} tz
 * @returns {String}
 */
export function formatInTimeZone(date, formatString, tz) {
    // https://stackoverflow.com/a/63227335/801454
    return format(utcToZonedTime(date, tz), formatString, { timeZone: tz });
}

/**
 * Parse query string
 * @param {String} queryString
 * @returns {Object}
 */
export function parseQueryString(queryString) {
    if (!queryString) {
        return {};
    }

    const query = decodeURIComponent(queryString).split('&');
    const result = {};

    for (const i in query) {
        if (Object.prototype.hasOwnProperty.call(query, i)) {
            const keyValue = query[i].split('=');

            result[keyValue[0]] = keyValue[1];
        }
    }

    return result;
}

/**
 * Convert object to query string
 * @param {Object} query
 * @returns {String}
 */
export function createQueryString(query) {
    const mapKey = (key) => encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);

    return Object.keys(query).map(mapKey).join('&');
}

export function getLocation(handler) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            const { coords } = position;
            const _position = {
                lat: coords.latitude,
                lng: coords.longitude
            };

            handler && handler(true, _position);
        }, () => {
            handler && handler(false, 'LOCATION_NOT_ALLOWED');
        });
    } else {
        // Browser doesn't support Geolocation
        handler && handler(false, 'LOCATION_NOT_SUPPORTED');
    }
}
