import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { APP_LANGUAGE_DEFAULT } from 'config/constants';
import { getAppLanguage } from 'utils/helpers';
import landingScreen from './i18n-resources/landingScreen';
import common from './i18n-resources/common';
import servicesScreen from './i18n-resources/servicesScreen';
import addServiceScreen from './i18n-resources/addServiceScreen';
import detailsScreen from './i18n-resources/detailsScreen';
import loginScreen from './i18n-resources/loginScreen';

export const languages = [
    { id: 1, name: 'En', key: 'en' },
    { id: 2, name: 'Fr', key: 'fr' }
];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        lng: getAppLanguage(),
        fallbackLng: APP_LANGUAGE_DEFAULT,
        resources: {
            en: {
                translation: {
                    ...common.en,
                    ...landingScreen.en,
                    ...servicesScreen.en,
                    ...addServiceScreen.en,
                    ...detailsScreen.en,
                    ...loginScreen.en
                }
            },
            fr: {
                translation: {
                    ...common.fr,
                    ...landingScreen.fr,
                    ...servicesScreen.fr,
                    ...addServiceScreen.fr,
                    ...detailsScreen.fr,
                    ...loginScreen.fr
                }
            }
        }
    });

/***
 *
 * How to handle app language change
 * see: https://react.i18next.com/guides/quick-start
 *
 */
// import { withTranslation } from 'react-i18next';
// import { setAppLanguage } from 'redux/reducers/i18n';
//
// class Container extends PureComponent {
//     static propTypes = {
//         // connect()
//         setAppLanguage: PropTypes.func.isRequired,
//
//         // withTranslation()
//         t: PropTypes.func.isRequired
//     }
//
//     setLanguage = (language) => {
//         const { setAppLanguage } = this.props;
//
//         setAppLanguage(language);
//     }
//
//     render() {
//         const { t } = this.props;
//
//         return (
//             <>
//                 <div onClick={() => this.setLanguage('en')} />
//                 <div onClick={() => this.setLanguage('de')} />
//
//                 <div>
//                     {t('TEXT_CONSTANT_HERE')}
//                 </div>
//             </>
//         );
//     }
// }
//
// const mapDispatchToProps = (dispatch) => ({
//     setAppLanguage: (language) => {
//         dispatch(setAppLanguage(language));
//     }
// });
//
// export default connect(null, mapDispatchToProps)(
//     withTranslation()(Container)
// );
