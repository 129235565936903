import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import { pushAlert, ALERT_TYPES } from 'creatella-react-components/lib/reduxReducers/alerts';
import EditServiceScreen from 'screens/EditService/EditServiceScreen';
import { updateService, getServiceById } from 'api/service';
import { getCategories, getSubCategories, getSubSubCategories } from 'api/category';
import { prepareDataToSend } from './helpers';
import { useTranslation } from 'react-i18next';
import AppLoading from 'containers/App/components/Loading/AppLoading';
import { goBack, push } from 'connected-react-router';
import { ROUTES } from 'config/constants';

function EditServiceContainer(props) {
    const { apiCaller, match } = props;
    const [isProcessing, setIsProcessing] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subSubCategories, setSubSubCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [service, setService] = useState({ isLoading: false, data: null });
    const { params } = match;
    const { id: serviceId } = params;

    const dispatch = useDispatch();
    const { language, profileId } = useSelector(state => {
        return {
            language: state.i18n.language,
            profileId: state.auth.profile?.id ?? null
        };
    });

    const { t } = useTranslation();

    useEffect(() => {
        fetchCategories();
        fetchService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchService = async () => {
        setService((prevValue) => ({ ...prevValue, isLoading: true }));

        try {
            const { data: service } = await apiCaller(getServiceById, serviceId);

            if (service.owner.id === profileId) {
                setService({ isLoading: false, data: service });
            } else {
                dispatch(goBack());
            }
        } catch (error) {
            //
        }
    };

    const fetchCategories = async () => {
        try {
            const categoriesRes = await apiCaller(getCategories);

            const subCategoriesRes = await apiCaller(getSubCategories);

            const subSubCategoriesRes = await apiCaller(getSubSubCategories);

            setCategories(categoriesRes.data.filter(c => c.id !== 4).map(i => ({
                id: i.id,
                name: language === 'fr' ? i.name_fr : i.name
            })));

            setSubCategories(subCategoriesRes.data.map(i => ({
                id: i.id,
                name: language === 'fr' ? i.name_fr : i.name,
                category_id: i.category_id
            })));

            setSubSubCategories(subSubCategoriesRes.data.map(i => ({
                id: i.id,
                name: language === 'fr' ? i.name_fr : i.name,
                sub_category: i.sub_category_id
            })));

            setIsLoading(false);
        } catch (e) {
            dispatch(pushAlert({
                type: ALERT_TYPES.ERROR,
                message: t('COMMON_ERROR')
            }));
        }
    };

    const onSubmit = async (data) => {
        const newData = prepareDataToSend(data);

        setIsProcessing(true);

        try {
            await apiCaller(updateService, service.data.id, newData);
            setIsProcessing(false);

            dispatch(push(ROUTES.SERVICE(service.data.id)));

            dispatch(pushAlert({
                type: ALERT_TYPES.SUCCESS,
                message: t('ADDSERVICE_ADDED')
            }));
        } catch (error) {
            setIsProcessing(false);
        }
    };

    const onFormError = () => {
        dispatch(pushAlert({
            type: ALERT_TYPES.ERROR,
            message: t('COMMON_REQUIREDERROR')
        }));
    };

    return (isLoading || service.isLoading)
        ? <AppLoading />
        : <EditServiceScreen
            {...props}
            service={service.data}
            categories={categories}
            subCategories={subCategories}
            subSubCategories={subSubCategories}
            isProcessing={isProcessing}
            onFormError={onFormError}
            onSubmitForm={onSubmit} />;
}

EditServiceContainer.propTypes = {
    apiCaller: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        language: state.i18n.language
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withAsyncCaller(
    connect(mapStateToProps, mapDispatchToProps)(EditServiceContainer)
);
