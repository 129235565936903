import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputGroup from 'creatella-react-components/lib/InputGroup';
import ItemPickerView from 'creatella-react-components/lib/ItemPickerView';
import Input from 'creatella-react-components/lib/Input';
import Button from 'creatella-react-components/lib/Button';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import ImageUpload from 'components/FormControl/ImageUpload/ImageUpload';
import TimePicker from 'components/FormControl/TimePicker/TimePicker';
import { BUTTON_CLASSNAMES } from 'config/styles';
import { DEFAULT_FORM_STATE, getFields } from './helpers';
import './EditServiceScreen.scss';
import { useTranslation } from 'react-i18next';

export default function EditServiceScreen(props) {
    const { isProcessing, onSubmitForm, onFormError } = props;
    const [formData, setFormData] = useState(DEFAULT_FORM_STATE);
    const [isFirstValidation, setIsFirstValidation] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const website = props.service?.media.find((item) => item.type === 4);
        const images = props.service?.media.filter((item) => item.type === 2);
        const videos = props.service?.media.filter((item) => item.type === 3);
        const docs = props.service?.media.filter((item) => item.type === 1);

        setFormData((prevValue) => ({
            ...prevValue,
            logo: {
                value: props.service?.logo ?? ''
            },
            name: {
                value: props.service?.name ?? ''
            },
            phone_work: {
                value: props.service?.phone_work ?? ''
            },
            phone: {
                value: props.service?.phone ?? ''
            },
            work_address_line1: {
                value: props.service?.locations[0] ? props.service.locations[0].address_line1 : ''
            },
            work_city: {
                value: props.service?.locations[0] ? props.service.locations[0].city : ''
            },
            work_country: {
                value: props.service?.locations[0] ? props.service.locations[0].country : ''
            },
            url: {
                value: website?.link ?? ''
            },
            phone_office_fax: {
                value: props.service?.phone_office_fax ?? ''
            },
            phone_other_fax: {
                value: props.service?.phone_other_fax ?? ''
            },
            work_zipcode: {
                value: props.service?.locations[0] ? props.service.locations[0].zipcode : ''
            },
            email_work: {
                value: props.service?.email_work ?? ''
            },
            description: {
                value: props.service?.description ?? ''
            },
            category_id: {
                value: props.service?.category?.id ?? ''
            },
            sub_category: {
                value: props.service?.sub_category_id ?? ''
            },
            sub_sub_category: {
                value: props.service?.sub_sub_categories?.map((service) => service.id) ?? []
            },
            picture1: {
                value: images[0]?.link
            },
            picture2: {
                value: images[1]?.link
            },
            picture3: {
                value: images[2]?.link
            },
            video1: {
                value: videos[0]?.link
            },
            video2: {
                value: videos[1]?.link
            },
            video3: {
                value: videos[2]?.link
            },
            document1: {
                value: docs[0]?.link
            },
            document2: {
                value: docs[1]?.link
            },
            document3: {
                value: docs[2]?.link
            },
            monday_start: {
                value: props.service?.timings[0]?.open_at ?? ''
            },
            monday_end: {
                value: props.service?.timings[0]?.close_at ?? ''
            },
            monday_start_break: {
                value: props.service?.timings[0]?.break_from ?? ''
            },
            monday_end_break: {
                value: props.service?.timings[0]?.break_to ?? ''
            },
            tuesday_start: {
                value: props.service?.timings[1]?.open_at ?? ''
            },
            tuesday_end: {
                value: props.service?.timings[1]?.close_at ?? ''
            },
            tuesday_start_break: {
                value: props.service?.timings[1]?.break_from ?? ''
            },
            tuesday_end_break: {
                value: props.service?.timings[1]?.break_to ?? ''
            },
            wednesday_start: {
                value: props.service?.timings[2]?.open_at ?? ''
            },
            wednesday_end: {
                value: props.service?.timings[2]?.close_at ?? ''
            },
            wednesday_start_break: {
                value: props.service?.timings[2]?.break_from ?? ''
            },
            wednesday_end_break: {
                value: props.service?.timings[2]?.break_to ?? ''
            },
            thursday_start: {
                value: props.service?.timings[3]?.open_at ?? ''
            },
            thursday_end: {
                value: props.service?.timings[3]?.close_at ?? ''
            },
            thursday_start_break: {
                value: props.service?.timings[3]?.break_from ?? ''
            },
            thursday_end_break: {
                value: props.service?.timings[3]?.break_to ?? ''
            },
            friday_start: {
                value: props.service?.timings[4]?.open_at ?? ''
            },
            friday_end: {
                value: props.service?.timings[4]?.close_at ?? ''
            },
            friday_start_break: {
                value: props.service?.timings[4]?.break_from ?? ''
            },
            friday_end_break: {
                value: props.service?.timings[4]?.break_to ?? ''
            },
            saturday_start: {
                value: props.service?.timings[5]?.open_at ?? ''
            },
            saturday_end: {
                value: props.service?.timings[5]?.close_at ?? ''
            },
            saturday_start_break: {
                value: props.service?.timings[5]?.break_from ?? ''
            },
            saturday_end_break: {
                value: props.service?.timings[5]?.break_to ?? ''
            },
            sunday_start: {
                value: props.service?.timings[6]?.open_at ?? ''
            },
            sunday_end: {
                value: props.service?.timings[6]?.close_at ?? ''
            },
            sunday_start_break: {
                value: props.service?.timings[6]?.break_from ?? ''
            },
            sunday_end_break: {
                value: props.service?.timings[6]?.break_to ?? ''
            }
        }));
    }, [props.service]);

    // default valid value check function
    const isValid = (value) => {
        return !!value;
    };

    const isOnlyNumbers = (value) => {
        return value === '' || value === undefined || /^\d+$/.test(value);
    };

    const isOnlyNumbersWithPlus = (value) => {
        return value === '' || value === undefined || /^(?:[+\d].*\d|\d)$/.test(value);
    };

    const isArrayWithValue = (value) => {
        return Array.isArray(value);
    };

    const FIELDS = getFields(
        props,
        formData,
        { isValid, isOnlyNumbers, isEmail, isURL, isArrayWithValue, isOnlyNumbersWithPlus },
        { onChangeItemPickerView, onChangeTimePicker, onChangeMultiItemPickerView }
    );

    const FORM = {
        ROW_1: [
            {
                label: 'ADDSERVICE_IMAGE',
                inputProps: FIELDS.logo,
                renderInput: renderInputFile
            }
        ],
        ROW_2: [
            {
                label: 'ADDSERVICE_BUSINESS',
                inputProps: FIELDS.name
            },
            {
                label: 'ADDSERVICE_BUSINESSPHONE',
                inputProps: FIELDS.phone_work
            },
            {
                label: 'ADDSERVICE_WORKPHONE',
                inputProps: FIELDS.phone
            }
        ],
        ROW_3: [
            {
                label: 'ADDSERVICE_WORKADDRESS',
                inputProps: FIELDS.work_address_line1
            },
            {
                label: 'ADDSERVICE_WORKCITY',
                inputProps: FIELDS.work_city
            },
            {
                label: 'ADDSERVICE_WORKCOUNTRY',
                inputProps: FIELDS.work_country
            }
        ],
        ROW_4: [
            {
                label: 'ADDSERVICE_WEBSITE',
                inputProps: FIELDS.url
            },
            {
                label: 'ADDSERVICE_FAXWORK',
                inputProps: FIELDS.phone_office_fax
            },
            {
                label: 'ADDSERVICE_FAXOTHER',
                inputProps: FIELDS.phone_other_fax
            }
        ],
        ROW_5: [
            {
                label: 'ADDSERVICE_WORKZIP',
                inputProps: FIELDS.work_zipcode
            },
            {
                label: 'ADDSERVICE_BUSINESSEMAIL',
                inputProps: FIELDS.email_work
            },
            {
                label: 'ADDSERVICE_ABOUT',
                inputProps: FIELDS.description
            }
        ],
        ROW_6: [
            {
                label: 'COMMON_CATEGORY',
                inputProps: FIELDS.category_id,
                renderInput: renderItemPicker
            },
            {
                label: 'COMMON_SUBCATEGORY',
                inputProps: FIELDS.sub_category,
                renderInput: renderItemPicker
            },
            {
                label: 'COMMON_SUBSUBCATEGORY',
                inputProps: FIELDS.sub_sub_category,
                renderInput: renderItemPicker
            }
        ],
        ROW_7: [
            {
                label: 'ADDSERVICE_IMAGES',
                items: [
                    {
                        inputProps: FIELDS.picture1,
                        renderInput: renderInputFile
                    },
                    {
                        inputProps: FIELDS.picture2,
                        renderInput: renderInputFile
                    },
                    {
                        inputProps: FIELDS.picture3,
                        renderInput: renderInputFile
                    }
                ]
            },
            {
                label: 'ADDSERVICE_VIDEOS',
                items: [
                    {
                        inputProps: FIELDS.video1,
                        renderInput: renderInputFile
                    },
                    {
                        inputProps: FIELDS.video2,
                        renderInput: renderInputFile
                    },
                    {
                        inputProps: FIELDS.video3,
                        renderInput: renderInputFile
                    }
                ]
            }
        ],
        ROW_8: [
            {
                label: 'ADDSERVICE_DOCS',
                items: [
                    {
                        inputProps: FIELDS.document1,
                        renderInput: renderInputFile
                    },
                    {
                        inputProps: FIELDS.document2,
                        renderInput: renderInputFile
                    },
                    {
                        inputProps: FIELDS.document3,
                        renderInput: renderInputFile
                    }
                ]
            }
        ],
        ROW_9: [
            {
                label: 'ADDSERVICE_MONDAYSTART',
                inputProps: FIELDS.monday_start,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_MONDAYEND',
                inputProps: FIELDS.monday_end,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_MONDAYBREAKSTART',
                inputProps: FIELDS.monday_start_break,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_MONDAYBREAKEND',
                inputProps: FIELDS.monday_end_break,
                renderInput: renderTimePicker
            }
        ],
        ROW_10: [
            {
                label: 'ADDSERVICE_TUESDAYSTART',
                inputProps: FIELDS.tuesday_start,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_TUESDAYEND',
                inputProps: FIELDS.tuesday_end,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_TUESDAYBREAKSTART',
                inputProps: FIELDS.tuesday_start_break,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_TUESDAYBREAKEND',
                inputProps: FIELDS.tuesday_end_break,
                renderInput: renderTimePicker
            }
        ],
        ROW_11: [
            {
                label: 'ADDSERVICE_WEDNESDAYSTART',
                inputProps: FIELDS.wednesday_start,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_WEDNESDAYEND',
                inputProps: FIELDS.wednesday_end,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_WEDNESDAYBREAKSTART',
                inputProps: FIELDS.wednesday_start_break,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_WEDNESDAYBREAKEND',
                inputProps: FIELDS.wednesday_end_break,
                renderInput: renderTimePicker
            }
        ],
        ROW_12: [
            {
                label: 'ADDSERVICE_THURSDAYSTART',
                inputProps: FIELDS.thursday_start,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_THURSDAYEND',
                inputProps: FIELDS.thursday_end,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_THURSDAYBREAKSTART',
                inputProps: FIELDS.thursday_start_break,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_THURSDAYBREAKEND',
                inputProps: FIELDS.thursday_end_break,
                renderInput: renderTimePicker
            }
        ],
        ROW_13: [
            {
                label: 'ADDSERVICE_FRIDAYSTART',
                inputProps: FIELDS.friday_start,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_FRIDAYEND',
                inputProps: FIELDS.friday_end,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_FRIDAYBREAKSTART',
                inputProps: FIELDS.friday_start_break,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_FRIDAYBREAKEND',
                inputProps: FIELDS.friday_end_break,
                renderInput: renderTimePicker
            }
        ],
        ROW_14: [
            {
                label: 'ADDSERVICE_SATURDAYSTART',
                inputProps: FIELDS.saturday_start,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_SATURDAYEND',
                inputProps: FIELDS.saturday_end,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_SATURDAYBREAKSTART',
                inputProps: FIELDS.saturday_start_break,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_SATURDAYBREAKEND',
                inputProps: FIELDS.saturday_end_break,
                renderInput: renderTimePicker
            }
        ],
        ROW_15: [
            {
                label: 'ADDSERVICE_SUNDAYSTART',
                inputProps: FIELDS.sunday_start,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_SUNDAYEND',
                inputProps: FIELDS.sunday_end,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_SUNDAYBREAKSTART',
                inputProps: FIELDS.sunday_start_break,
                renderInput: renderTimePicker
            },
            {
                label: 'ADDSERVICE_SUNDAYBREAKEND',
                inputProps: FIELDS.sunday_end_break,
                renderInput: renderTimePicker
            }
        ],
        ROW_16: [
            {
                label: 'COMMON_FIRSTNAME',
                inputProps: FIELDS.first_name
            },
            {
                label: 'COMMON_MIDDLENAME',
                inputProps: FIELDS.middle_name
            },
            {
                label: 'COMMON_LASTNAME',
                inputProps: FIELDS.last_name
            }
        ],
        ROW_17: [
            {
                label: 'ADDSERVICE_PROFESSION',
                inputProps: FIELDS.profession
            },
            {
                label: 'ADDSERVICE_SELECTSERVICE',
                inputProps: FIELDS.service
            }
        ],
        ROW_18: [
            {
                label: 'ADDSERVICE_MOBILE',
                inputProps: FIELDS.phone_mobile

            },
            {
                label: 'ADDSERVICE_PHONE',
                inputProps: FIELDS.phone_home
            },
            {
                label: 'ADDSERVICE_EMAIL',
                inputProps: FIELDS.email
            }
        ],
        ROW_19: [
            {
                label: 'ADDSERVICE_HOMEADDRESS',
                inputProps: FIELDS.home_address_line1
            },
            {
                label: 'ADDSERVICE_HOMEZIP',
                inputProps: FIELDS.home_zipcode
            }
        ]
    };

    if (!isFirstValidation) {
        validateForm();
        setIsFirstValidation(true);
    }

    const onChangeInputFile = (name, value) => {
        const field = FIELDS[name];
        const { inputValidator } = field;

        setFormData((formData) => ({
            ...formData,
            [name]: {
                value,
                isValid: inputValidator ? inputValidator(value) : undefined
            }
        }));
    };

    function onChangeItemPickerView(name) {
        return (value) => {
            const field = FIELDS[name];
            const { inputValidator } = field;

            setFormData((formData) => ({
                ...formData,
                sub_category: name === 'category_id' ? { ...formData.sub_category, value: '' } : formData.sub_category,
                sub_sub_category: name === 'category_id' || name === 'sub_category' ? { ...formData.sub_sub_category, value: [] } : formData.sub_sub_category,
                [name]: {
                    value: parseInt(value),
                    isValid: inputValidator ? inputValidator(value) : undefined
                }

            }));
        };
    }

    function onChangeMultiItemPickerView(name) {
        return (value) => {
            const field = FIELDS[name];
            const { inputValidator } = field;

            setFormData((formData) => ({
                ...formData,
                [name]: {
                    value,
                    isValid: inputValidator ? inputValidator(value) : undefined
                }
            }));
        };
    }

    function onChangeTimePicker(name) {
        return (value) => {
            const field = FIELDS[name];
            const { inputValidator } = field;

            setFormData((formData) => ({
                ...formData,
                [name]: {
                    value,
                    isValid: inputValidator ? inputValidator(value) : undefined
                }
            }));
        };
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        const field = FIELDS[name];
        const { inputValidator } = field;

        setFormData((formData) => ({
            ...formData,
            [name]: {
                value,
                isValid: inputValidator ? inputValidator(value) : undefined
            }
        }));
    };

    const onSubmit = async () => {
        if (validateForm()) {
            await onSubmitForm(formData);
        } else {
            onFormError();
        }
    };

    function validateForm() {
        const keys = Object.keys(FIELDS);
        let isFormValid = true;

        for (const key of keys) {
            const data = formData[key];
            const field = FIELDS[key];

            if (field && field.inputValidator) {
                const isValid = data && data.value ? field.inputValidator(data.value) : !field.required;

                if (!isValid) {
                    isFormValid = false;

                    setFormData((formData) => ({
                        ...formData,
                        [key]: {
                            value: data ? data.value : undefined,
                            isValid: isValid
                        }
                    }));
                }
            }
        }

        return isFormValid;
    }

    const renderInputGroup = (item) => {
        const { label, items, className = '', inputProps, renderInput } = item;
        const renderInputItem = (item) => item.renderInput(item.inputProps);

        let isValid;

        if (inputProps && inputProps.name) {
            const { name } = inputProps;

            isValid = formData[name] ? formData[name].isValid : undefined;
        }

        return (
            <InputGroup
                key={item.label}
                className={`ServiceFormScreen__body-row-InputGroup ${className}`}>
                <label className='ServiceFormScreen__body-row-InputGroup-label'>
                    {t(label)}
                </label>

                {items
                    ? (<div className='ServiceFormScreen__body-row-InputGroup-items'>
                        {items.map(renderInputItem)}
                    </div>)
                    : (renderInput
                        ? renderInput(inputProps)
                        : <Input
                            className='ServiceFormScreen__body-row-InputGroup-Input'
                            {...inputProps}
                            placeholder={inputProps.placeholder ? t(inputProps.placeholder) : ''}
                            isInvalid={isValid !== undefined ? !isValid : undefined}
                            onChange={onChange} />)}
            </InputGroup>
        );
    };

    const renderInputRow = (items, className = '') => {
        return (
            <div className={`ServiceFormScreen__body-row ${className}`}>
                {items.map(renderInputGroup)}
            </div>
        );
    };

    function renderInputFile(inputProps) {
        const { name, label } = inputProps;
        const isValid = formData[name] ? formData[name].isValid : undefined;

        return (
            <ImageUpload
                key={inputProps.name}
                {...inputProps}
                isInvalid={isValid !== undefined ? !isValid : undefined}
                label={t(label)}
                placeholder={inputProps.placeholder ? t(inputProps.placeholder) : ''}
                onChange={onChangeInputFile} />
        );
    }

    function renderItemPicker(inputProps) {
        const { name } = inputProps;
        const value = formData[name] ? formData[name].value : undefined;
        const isValid = formData[name] ? formData[name].isValid : undefined;

        return (
            <ItemPickerView
                {...inputProps}
                value={value || []}
                placeholder={inputProps.placeholder ? t(inputProps.placeholder) : ''}
                isInvalid={isValid !== undefined ? !isValid : undefined} />
        );
    }

    function renderTimePicker(inputProps) {
        const { name } = inputProps;
        const value = formData[name] ? formData[name].value : undefined;
        const isValid = formData[name] ? formData[name].isValid : undefined;

        return (
            <TimePicker
                {...inputProps}
                value={value}
                isInvalid={isValid !== undefined ? !isValid : undefined} />

        );
    }

    return (
        <div className='ServiceFormScreen'>
            <div className='ServiceFormScreen__header'>
                <div className='ServiceFormScreen__header-title'>
                    {/* {t('ADDSERVICE_HEADER')} */}
                    Edit Service
                </div>
            </div>

            <div className='ServiceFormScreen__body'>
                <div className='ServiceFormScreen__body-title'>
                    {t('ADDSERVICE_TITLE')}
                </div>

                {renderInputRow(FORM.ROW_1)}
                {renderInputRow(FORM.ROW_2)}
                {renderInputRow(FORM.ROW_3)}
                {renderInputRow(FORM.ROW_4)}
                {renderInputRow(FORM.ROW_5)}
                {renderInputRow(FORM.ROW_6)}
                {renderInputRow(FORM.ROW_7, 'ServiceFormScreen__body-row--col6')}
                {renderInputRow(FORM.ROW_8, 'ServiceFormScreen__body-row--col6')}

                <div className='ServiceFormScreen__body-title ServiceFormScreen__body-title--personal'>
                    {t('ADDSERVICE_WORKINGTIME')}
                    <p className='ServiceFormScreen__body-desc'>{t('ADDSERVICE_LEAVE_BLANK')}</p>
                </div>

                {renderInputRow(FORM.ROW_9, 'ServiceFormScreen__body-row--col4')}
                {renderInputRow(FORM.ROW_10, 'ServiceFormScreen__body-row--col4')}
                {renderInputRow(FORM.ROW_11, 'ServiceFormScreen__body-row--col4')}
                {renderInputRow(FORM.ROW_12, 'ServiceFormScreen__body-row--col4')}
                {renderInputRow(FORM.ROW_13, 'ServiceFormScreen__body-row--col4')}
                {renderInputRow(FORM.ROW_14, 'ServiceFormScreen__body-row--col4')}
                {renderInputRow(FORM.ROW_15, 'ServiceFormScreen__body-row--col4')}

                <Button
                    className={`ServiceFormScreen__body-submit ${BUTTON_CLASSNAMES.SOLID_GREEN_FORM}`}
                    label={t('ADDSERVICE_SUBMIT')}
                    isProcessing={isProcessing}
                    onClick={onSubmit} />
            </div>
        </div>
    );
}

EditServiceScreen.propTypes = {
    service: PropTypes.object.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    categories: PropTypes.array,
    subCategories: PropTypes.array,
    subSubCategories: PropTypes.array,
    onFormError: PropTypes.func.isRequired
};
