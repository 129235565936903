export default {
    en: {
        LOGIN_SCREEN_FORGOT_PASSWORD: 'Forgot password?',
        LOGIN_SCREEN_LOGIN: 'Login',
        LOGIN_SCREEN_LOGOUT: 'Log out'
    },
    fr: {
        LOGIN_SCREEN_LOGOUT: 'Se déconnecter',
        LOGIN_SCREEN_LOGIN: 'Connexion',
        LOGIN_SCREEN_FORGOT_PASSWORD: 'Passwort vergessen?'
    }
};
