/* eslint-disable import/no-anonymous-default-export */
export default {
    en: {
        ADDSERVICE_HEADER: 'Add Service',
        ADDSERVICE_TITLE: 'Upload an excel file and submit to list your businesses',
        ADDSERVICE_IMAGE: 'Upload Image/Logo',
        ADDSERVICE_BUSINESS: 'Business',
        ADDSERVICE_BUSINESSPHONE: 'Business Phone Number',
        ADDSERVICE_WORKPHONE: 'Mobile Phone Number (Work)',
        ADDSERVICE_BUSINESSEMAIL: 'Business Email Address',
        ADDSERVICE_WORKADDRESS: 'Address (Work)',
        ADDSERVICE_WORKCOUNTRY: 'Country',
        ADDSERVICE_WORKCITY: 'City',
        ADDSERVICE_WORKZIP: 'Zipcode (Work)',
        ADDSERVICE_WEBSITE: 'Website URL',
        ADDSERVICE_FAXWORK: 'Fax Number (Office)',
        ADDSERVICE_FAXOTHER: 'Fax Number (Other)',
        ADDSERVICE_ABOUT: 'About',
        ADDSERVICE_IMAGES: 'Upload Images',
        ADDSERVICE_VIDEOS: 'Upload Videos',
        ADDSERVICE_DOCS: 'Upload Documents',
        ADDSERVICE_MONDAYSTART: 'Monday Start Time',
        ADDSERVICE_MONDAYEND: 'Monday End Time',
        ADDSERVICE_MONDAYBREAKSTART: 'Monday Start Break',
        ADDSERVICE_MONDAYBREAKEND: 'Monday End Break',
        ADDSERVICE_TUESDAYSTART: 'Tuesday Start Time',
        ADDSERVICE_TUESDAYEND: 'Tuesday End Time',
        ADDSERVICE_TUESDAYBREAKSTART: 'Tuesday Start Break',
        ADDSERVICE_TUESDAYBREAKEND: 'Tuesday End Break',
        ADDSERVICE_WEDNESDAYSTART: 'Wednesday Start Time',
        ADDSERVICE_WEDNESDAYEND: 'Wednesday End Time',
        ADDSERVICE_WEDNESDAYBREAKSTART: 'Wednesday Start Break',
        ADDSERVICE_WEDNESDAYBREAKEND: 'Wednesday End Break',
        ADDSERVICE_THURSDAYSTART: 'Thursday Start Time',
        ADDSERVICE_THURSDAYEND: 'Thursday End Time',
        ADDSERVICE_THURSDAYBREAKSTART: 'Thursday Start Break',
        ADDSERVICE_THURSDAYBREAKEND: 'Thursday End Break',
        ADDSERVICE_FRIDAYSTART: 'Friday Start Time',
        ADDSERVICE_FRIDAYEND: 'Friday End Time',
        ADDSERVICE_FRIDAYBREAKSTART: 'Friday Start Break',
        ADDSERVICE_FRIDAYBREAKEND: 'Friday End Break',
        ADDSERVICE_SATURDAYSTART: 'Saturday Start Time',
        ADDSERVICE_SATURDAYEND: 'Saturday End Time',
        ADDSERVICE_SATURDAYBREAKSTART: 'Saturday Start Break',
        ADDSERVICE_SATURDAYBREAKEND: 'Saturday End Break',
        ADDSERVICE_SUNDAYSTART: 'Sunday Start Time',
        ADDSERVICE_SUNDAYEND: 'Sunday End Time',
        ADDSERVICE_SUNDAYBREAKSTART: 'Sunday Start Break',
        ADDSERVICE_SUNDAYBREAKEND: 'Sunday End Break',
        ADDSERVICE_PROFESSION: 'Select Profession',
        ADDSERVICE_SELECTSERVICE: 'Select Service',
        ADDSERVICE_MOBILE: 'Mobile Number',
        ADDSERVICE_PHONE: 'Home Phone Number',
        ADDSERVICE_EMAIL: 'Email Address',
        ADDSERVICE_HOMEADDRESS: 'Address (Home)',
        ADDSERVICE_HOMEZIP: 'Zipcode (Home)',
        ADDSERVICE_SUBMIT: 'Submit',
        ADDSERVICE_WORKINGTIME: 'Working time',
        ADDSERVICE_P_BUSINESSNAME: 'Write business name',
        ADDSERVICE_P_WORKEMAIL: 'Write email address',
        ADDSERVICE_P_WORKADDRESS: 'Write: Street no...',
        ADDSERVICE_P_WORKCITY: 'Write: city',
        ADDSERVICE_P_WORKCOUNTRY: 'Write: country',
        ADDSERVICE_P_WORKZIP: 'Write zipcode',
        ADDSERVICE_P_WEBSITE: 'Write website',
        ADDSERVICE_P_FAX: 'Write fax number',
        ADDSERVICE_P_BUSINESS: 'Write about your business',
        ADDSERVICE_P_SELECTCAT: 'Select Category',
        ADDSERVICE_P_SELECTSUBCAT: 'Select Sub Category',
        ADDSERVICE_P_SELECTSUBSUBCAT: 'Select Sub-Sub Category',
        ADDSERVICE_P_UPLOADIMAGE: 'Tap to upload image/logo',
        ADDSERVICE_P_UPLOADVIDEO: 'Tap to upload video',
        ADDSERVICE_P_UPLOADDOC: 'Tap to upload document',
        ADDSERVICE_P_FIRSTNAME: 'Write first name',
        ADDSERVICE_P_MIDDLENAME: 'Write middle name',
        ADDSERVICE_P_LASTNAME: 'Write last name',
        ADDSERVICE_P_PROFESSION: 'Write profession',
        ADDSERVICE_P_SERVICE: 'Write service',
        ADDSERVICE_P_MOBILE: 'Write mobile number',
        ADDSERVICE_P_PHONE: '+33...',
        ADDSERVICE_P_ZIP: 'Write zipcode',
        ADDSERVICE_LEAVE_BLANK: 'Leave it blank for off days',
        ADDSERVICE_ADDED: 'Service(s) added',
        ADDSERVICE_PERSONALINFO: 'Personal Information',
        ADDSERVICE_LENGTH: 'You are about to add: ',
        ADDSERVICE_CHOOSEFILE: 'Choose your file',
        ADDSERVICE_CHOOSESIRENEFILE: 'Choose your Sirene file'
    },
    fr: {
        ADDSERVICE_CHOOSESIRENEFILE: 'Choisir votre Sirene fichier',
        ADDSERVICE_CHOOSEFILE: 'Choisir votre fichier',
        ADDSERVICE_LENGTH: 'Vous êtes sur le point d\'ajouter: ',
        ADDSERVICE_HEADER: 'Ajouter service',
        ADDSERVICE_TITLE: 'Uploader un fichier Excel et soumettez-le pour référencer vos services',
        ADDSERVICE_IMAGE: 'Télécharger une image / logo',
        ADDSERVICE_BUSINESS: 'Nom du service',
        ADDSERVICE_BUSINESSPHONE: 'Numéro de téléphone professionnel',
        ADDSERVICE_WORKPHONE: 'Téléphone mobile (travail)',
        ADDSERVICE_BUSINESSEMAIL: 'Adresse e-mail professionnelle',
        ADDSERVICE_WORKADDRESS: 'Adresse professionnelle',
        ADDSERVICE_WORKCOUNTRY: 'Pays',
        ADDSERVICE_WORKCITY: 'Ville',
        ADDSERVICE_WORKZIP: 'Code postal professionnel',
        ADDSERVICE_WEBSITE: 'URL de site web ',
        ADDSERVICE_FAXWORK: 'Numéro de fax (bureau) Téléphone bureau',
        ADDSERVICE_FAXOTHER: 'Numéro de fax (autre)',
        ADDSERVICE_ABOUT: 'Description',
        ADDSERVICE_IMAGES: 'Téléchargez les images',
        ADDSERVICE_VIDEOS: 'Téléchargez les vidéos',
        ADDSERVICE_DOCS: 'Téléchargez les documents',
        ADDSERVICE_MONDAYSTART: 'Lundi, Heure de début',
        ADDSERVICE_MONDAYEND: 'Lundi, Heure de fin',
        ADDSERVICE_MONDAYBREAKSTART: 'Lundi, Début de pause',
        ADDSERVICE_MONDAYBREAKEND: 'Lundi, Fin de pause',
        ADDSERVICE_TUESDAYSTART: 'Mardi, Heure de début',
        ADDSERVICE_TUESDAYEND: 'Mardi, Heure de fin',
        ADDSERVICE_TUESDAYBREAKSTART: 'Mardi, Début de pause',
        ADDSERVICE_TUESDAYBREAKEND: 'Mardi, Fin de pause',
        ADDSERVICE_WEDNESDAYSTART: 'Mercredi, Heure de début',
        ADDSERVICE_WEDNESDAYEND: 'Mercredi, Heure de fin',
        ADDSERVICE_WEDNESDAYBREAKSTART: 'Mercredi, Début de pause',
        ADDSERVICE_WEDNESDAYBREAKEND: 'Mercredi, Fin de pause',
        ADDSERVICE_THURSDAYSTART: 'Jeudi, Heure de début',
        ADDSERVICE_THURSDAYEND: 'Jeudi, Heure de fin',
        ADDSERVICE_THURSDAYBREAKSTART: 'Jeudi, Début de pause',
        ADDSERVICE_THURSDAYBREAKEND: 'Jeudi, Fin de pause',
        ADDSERVICE_FRIDAYSTART: 'Vendredi, Heure de début',
        ADDSERVICE_FRIDAYEND: 'Vendredi, Heure de fin',
        ADDSERVICE_FRIDAYBREAKSTART: 'Vendredi, Début de pause',
        ADDSERVICE_FRIDAYBREAKEND: 'Vendredi, Fin de pause',
        ADDSERVICE_SATURDAYSTART: 'Samedi, Heure de début',
        ADDSERVICE_SATURDAYEND: 'Samedi, Heure de fin',
        ADDSERVICE_SATURDAYBREAKSTART: 'Samedi, Début de pause',
        ADDSERVICE_SATURDAYBREAKEND: 'Samedi, Fin de pause',
        ADDSERVICE_SUNDAYSTART: 'Dimanche, Heure de début',
        ADDSERVICE_SUNDAYEND: 'Dimanche, Heure de fin',
        ADDSERVICE_SUNDAYBREAKSTART: 'Dimanche, Début de pause',
        ADDSERVICE_SUNDAYBREAKEND: 'Dimanche, Fin de pause',
        ADDSERVICE_PROFESSION: 'Selectionnez votre profession',
        ADDSERVICE_SELECTSERVICE: 'Select votre service',
        ADDSERVICE_MOBILE: '"Téléphone portable',
        ADDSERVICE_PHONE: 'Téléphone domicile',
        ADDSERVICE_EMAIL: 'Adresse mail',
        ADDSERVICE_HOMEADDRESS: 'Adresse personnelle',
        ADDSERVICE_HOMEZIP: 'Code postal personnel',
        ADDSERVICE_SUBMIT: 'Soumettre',
        ADDSERVICE_WORKINGTIME: 'Heures d\'ouverture',
        ADDSERVICE_P_BUSINESSNAME: 'Tapez le nom du service/entreprise',
        ADDSERVICE_P_WORKEMAIL: 'Tapez adresse mail',
        ADDSERVICE_P_WORKADDRESS: 'Tapez le numéro et nom de la rue ; Ville ; Région ; Pays',
        ADDSERVICE_P_WORKCITY: 'Tapez: Ville',
        ADDSERVICE_P_WORKCOUNTRY: 'Tapez: Pays',
        ADDSERVICE_P_WORKZIP: 'Tapez code postal',
        ADDSERVICE_P_WEBSITE: 'Tapez de site web',
        ADDSERVICE_P_FAX: 'Tapez numéro de fax',
        ADDSERVICE_P_BUSINESS: 'Tapez la description de votre service',
        ADDSERVICE_P_SELECTCAT: 'Choisissez la catégorie',
        ADDSERVICE_P_SELECTSUBCAT: 'Choisissez la sous-catégorie',
        ADDSERVICE_P_SELECTSUBSUBCAT: 'Choisissez la sous-sous-catégorie',
        ADDSERVICE_P_UPLOADIMAGE: 'Cliquez pour télécharger l\'image / le logo',
        ADDSERVICE_P_UPLOADVIDEO: 'Tapez pour téléchargez les vidéos',
        ADDSERVICE_P_UPLOADDOC: 'Tapez pour téléchargez les documents',
        ADDSERVICE_P_FIRSTNAME: 'Tapez prénom',
        ADDSERVICE_P_MIDDLENAME: 'Tapez deuxième nom',
        ADDSERVICE_P_LASTNAME: 'Tapez nom de famille',
        ADDSERVICE_P_PROFESSION: 'Tapez profession',
        ADDSERVICE_P_SERVICE: 'Tapez service',
        ADDSERVICE_P_MOBILE: 'Tapez téléphone portable',
        ADDSERVICE_P_PHONE: '+33...',
        ADDSERVICE_P_ZIP: 'Tapez code postal',
        ADDSERVICE_LEAVE_BLANK: 'Laissez vide pendant les jours fériés',
        ADDSERVICE_ADDED: 'service(s) ajouté(s)',
        ADDSERVICE_PERSONALINFO: 'Information personnelle'

    }
};
