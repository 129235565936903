export const DEFAULT_FORM_STATE = {
    logo: {
        value: ''
    },
    name: {
        value: ''
    },
    phone_work: {
        value: ''
    },
    phone: {
        value: ''
    },
    email_work: {
        value: ''
    },
    work_address_line1: {
        value: ''
    },
    work_city: {
        value: ''
    },
    work_country: {
        value: 'France'
    },
    work_zipcode: {
        value: ''
    },
    url: {
        value: ''
    },
    phone_office_fax: {
        value: ''
    },
    phone_other_fax: {
        value: ''
    },
    description: {
        value: ''
    },
    category_id: {
        value: ''
    },
    sub_category: {
        value: ''
    },
    sub_sub_category: {
        value: []
    },
    picture1: {
        value: ''
    },
    picture2: {
        value: ''
    },
    picture3: {
        value: ''
    },
    video1: {
        value: ''
    },
    video2: {
        value: ''
    },
    video3: {
        value: ''
    },
    document1: {
        value: ''
    },
    document2: {
        value: ''
    },
    document3: {
        value: ''
    },
    monday_start: {
        value: ''
    },
    monday_end: {
        value: ''
    },
    monday_start_break: {
        value: ''
    },
    monday_end_break: {
        value: ''
    },
    tuesday_start: {
        value: ''
    },
    tuesday_end: {
        value: ''
    },
    tuesday_start_break: {
        value: ''
    },
    tuesday_end_break: {
        value: ''
    },
    wednesday_start: {
        value: ''
    },
    wednesday_end: {
        value: ''
    },
    wednesday_start_break: {
        value: ''
    },
    wednesday_end_break: {
        value: ''
    },
    thursday_start: {
        value: ''
    },
    thursday_end: {
        value: ''
    },
    thursday_start_break: {
        value: ''
    },
    thursday_end_break: {
        value: ''
    },
    friday_start: {
        value: ''
    },
    friday_end: {
        value: ''
    },
    friday_start_break: {
        value: ''
    },
    friday_end_break: {
        value: ''
    },
    saturday_start: {
        value: ''
    },
    saturday_end: {
        value: ''
    },
    saturday_start_break: {
        value: ''
    },
    saturday_end_break: {
        value: ''
    },
    sunday_start: {
        value: ''
    },
    sunday_end: {
        value: ''
    },
    sunday_start_break: {
        value: ''
    },
    sunday_end_break: {
        value: ''
    },
    first_name: {
        value: ''
    },
    middle_name: {
        value: ''
    },
    last_name: {
        value: ''
    },
    profession: {
        value: ''
    },
    service: {
        value: ''
    },
    phone_mobile: {
        value: ''
    },
    phone_home: {
        value: ''
    },
    email: {
        value: ''
    },
    home_address_line1: {
        value: ''
    },
    home_zipcode: {
        value: ''
    }
};

export const getFields = (props, formData, validators, handlers) => {
    const { categories, subCategories, subSubCategories } = props;
    const { isValid, isOnlyNumbers, isEmail, isURL, isArrayWithValue, isOnlyNumbersWithPlus } = validators;
    const { onChangeItemPickerView, onChangeTimePicker, onChangeMultiItemPickerView } = handlers;

    return {
        logo: {
            name: 'logo',
            inputValidator: isValid,
            required: true,
            label: 'ADDSERVICE_P_UPLOADIMAGE',
            value: formData.logo.value
        },
        name: {
            name: 'name',
            placeholder: 'ADDSERVICE_P_BUSINESSNAME',
            inputValidator: isValid,
            required: true,
            value: formData.name.value
        },
        phone_work: {
            name: 'phone_work',
            placeholder: 'ADDSERVICE_P_PHONE',
            inputValidator: isOnlyNumbersWithPlus,
            value: formData.phone_work.value
        },
        phone: {
            name: 'phone',
            placeholder: 'ADDSERVICE_P_PHONE',
            inputValidator: isOnlyNumbersWithPlus,
            value: formData.phone.value
        },
        email_work: {
            name: 'email_work',
            placeholder: 'ADDSERVICE_P_WORKEMAIL',
            inputValidator: isEmail,
            value: formData.email_work.value
        },
        work_address_line1: {
            name: 'work_address_line1',
            placeholder: 'ADDSERVICE_P_WORKADDRESS',
            value: formData.work_address_line1.value,
            required: true,
            inputValidator: isValid
        },
        work_city: {
            name: 'work_city',
            placeholder: 'ADDSERVICE_P_WORKCITY',
            value: formData.work_city.value,
            required: true,
            inputValidator: isValid
        },
        work_country: {
            name: 'work_country',
            placeholder: 'ADDSERVICE_P_WORKCOUNTRY',
            value: formData.work_country.value,
            required: true,
            inputValidator: isValid
        },
        work_zipcode: {
            name: 'work_zipcode',
            placeholder: 'ADDSERVICE_P_WORKZIP',
            value: formData.work_zipcode.value
        },
        url: {
            name: 'url',
            placeholder: 'ADDSERVICE_P_WEBSITE',
            inputValidator: isURL,
            value: formData.url.value
        },
        phone_office_fax: {
            name: 'phone_office_fax',
            placeholder: 'ADDSERVICE_P_FAX',
            inputValidator: isOnlyNumbers,
            value: formData.phone_office_fax.value
        },
        phone_other_fax: {
            name: 'phone_other_fax',
            placeholder: 'ADDSERVICE_P_FAX',
            inputValidator: isOnlyNumbers,
            value: formData.phone_other_fax.value
        },
        description: {
            name: 'description',
            placeholder: 'ADDSERVICE_P_BUSINESS',
            value: formData.description.value
        },
        category_id: {
            name: 'category_id',
            placeholder: 'ADDSERVICE_P_SELECTCAT',
            items: categories,
            inputValidator: isValid,
            onChange: onChangeItemPickerView('category_id'),
            required: true,
            value: formData.category_id.value
        },
        sub_category: {
            name: 'sub_category',
            placeholder: 'ADDSERVICE_P_SELECTSUBCAT',
            items: formData.category_id.value ? subCategories.filter(f => f.category_id === formData.category_id.value) : [],
            onChange: onChangeItemPickerView('sub_category'),
            value: formData.sub_category.value,
            required: false,
            inputValidator: isValid
        },
        sub_sub_category: {
            name: 'sub_sub_category',
            placeholder: 'ADDSERVICE_P_SELECTSUBSUBCAT',
            items: formData.sub_category.value ? subSubCategories.filter(f => f.sub_category === formData.sub_category.value) : [],
            onChange: onChangeMultiItemPickerView('sub_sub_category'),
            value: formData.sub_sub_category.value,
            required: false,
            inputValidator: isArrayWithValue
        },
        picture1: {
            name: 'picture1',
            isSmall: true,
            label: 'ADDSERVICE_P_UPLOADIMAGE',
            value: formData.picture1.value
        },
        picture2: {
            name: 'picture2',
            isSmall: true,
            label: 'ADDSERVICE_P_UPLOADIMAGE',
            value: formData.picture2.value
        },
        picture3: {
            name: 'picture3',
            isSmall: true,
            label: 'ADDSERVICE_P_UPLOADIMAGE',
            value: formData.picture3.value
        },
        video1: {
            name: 'video1',
            isSmall: true,
            value: formData.video1.value,
            label: 'ADDSERVICE_P_UPLOADVIDEO',
            type: 'video/mp4,video/x-m4v,video/*'

        },
        video2: {
            name: 'video2',
            isSmall: true,
            value: formData.video2.value,
            label: 'ADDSERVICE_P_UPLOADVIDEO',
            type: 'video/mp4,video/x-m4v,video/*'
        },
        video3: {
            name: 'video3',
            isSmall: true,
            value: formData.video3.value,
            label: 'ADDSERVICE_P_UPLOADVIDEO',
            type: 'video/mp4,video/x-m4v,video/*'

        },
        document1: {
            name: 'document1',
            isSmall: true,
            label: 'ADDSERVICE_P_UPLOADDOC',
            value: formData.document1.value,
            type: 'application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        },
        document2: {
            name: 'document2',
            isSmall: true,
            label: 'ADDSERVICE_P_UPLOADDOC',
            value: formData.document2.value,
            type: 'application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        },
        document3: {
            name: 'document3',
            isSmall: true,
            label: 'ADDSERVICE_P_UPLOADDOC',
            value: formData.document3.value,
            type: 'application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        },
        monday_start: {
            name: 'monday_start',
            onChange: onChangeTimePicker('monday_start'),
            value: formData.monday_start.value
        },
        monday_end: {
            name: 'monday_end',
            onChange: onChangeTimePicker('monday_end'),
            value: formData.monday_end.value
        },
        monday_start_break: {
            name: 'monday_start_break',
            onChange: onChangeTimePicker('monday_start_break'),
            value: formData.monday_start_break.value
        },
        monday_end_break: {
            name: 'monday_end_break',
            onChange: onChangeTimePicker('monday_end_break'),
            value: formData.monday_end_break.value
        },
        tuesday_start: {
            name: 'tuesday_start',
            onChange: onChangeTimePicker('tuesday_start'),
            value: formData.tuesday_start.value
        },
        tuesday_end: {
            name: 'tuesday_end',
            onChange: onChangeTimePicker('tuesday_end'),
            value: formData.tuesday_end.value
        },
        tuesday_start_break: {
            name: 'tuesday_start_break',
            onChange: onChangeTimePicker('tuesday_start_break'),
            value: formData.tuesday_start_break.value
        },
        tuesday_end_break: {
            name: 'tuesday_end_break',
            onChange: onChangeTimePicker('tuesday_end_break'),
            value: formData.tuesday_end_break.value
        },
        wednesday_start: {
            name: 'wednesday_start',
            onChange: onChangeTimePicker('wednesday_start'),
            value: formData.wednesday_start.value
        },
        wednesday_end: {
            name: 'wednesday_end',
            onChange: onChangeTimePicker('wednesday_end'),
            value: formData.wednesday_end.value
        },
        wednesday_start_break: {
            name: 'wednesday_start_break',
            onChange: onChangeTimePicker('wednesday_start_break'),
            value: formData.wednesday_start_break.value
        },
        wednesday_end_break: {
            name: 'wednesday_end_break',
            onChange: onChangeTimePicker('wednesday_end_break'),
            value: formData.wednesday_end_break.value
        },
        thursday_start: {
            name: 'thursday_start',
            onChange: onChangeTimePicker('thursday_start'),
            value: formData.thursday_start.value
        },
        thursday_end: {
            name: 'thursday_end',
            onChange: onChangeTimePicker('thursday_end'),
            value: formData.thursday_end.value
        },
        thursday_start_break: {
            name: 'thursday_start_break',
            onChange: onChangeTimePicker('thursday_start_break'),
            value: formData.thursday_start_break.value
        },
        thursday_end_break: {
            name: 'thursday_end_break',
            onChange: onChangeTimePicker('thursday_end_break'),
            value: formData.thursday_end_break.value
        },
        friday_start: {
            name: 'friday_start',
            onChange: onChangeTimePicker('friday_start'),
            value: formData.friday_start.value
        },
        friday_end: {
            name: 'friday_end',
            onChange: onChangeTimePicker('friday_end'),
            value: formData.friday_end.value
        },
        friday_start_break: {
            name: 'friday_start_break',
            onChange: onChangeTimePicker('friday_start_break'),
            value: formData.friday_start_break.value
        },
        friday_end_break: {
            name: 'friday_end_break',
            onChange: onChangeTimePicker('friday_end_break'),
            value: formData.friday_end_break.value
        },
        saturday_start: {
            name: 'saturday_start',
            onChange: onChangeTimePicker('saturday_start'),
            value: formData.saturday_start.value
        },
        saturday_end: {
            name: 'saturday_end',
            onChange: onChangeTimePicker('saturday_end'),
            value: formData.saturday_end.value
        },
        saturday_start_break: {
            name: 'saturday_start_break',
            onChange: onChangeTimePicker('saturday_start_break'),
            value: formData.saturday_start_break.value
        },
        saturday_end_break: {
            name: 'saturday_end_break',
            onChange: onChangeTimePicker('saturday_end_break'),
            value: formData.saturday_end_break.value
        },
        sunday_start: {
            name: 'sunday_start',
            onChange: onChangeTimePicker('sunday_start'),
            value: formData.sunday_start.value
        },
        sunday_end: {
            name: 'sunday_end',
            onChange: onChangeTimePicker('sunday_end'),
            value: formData.sunday_end.value
        },
        sunday_start_break: {
            name: 'sunday_start_break',
            onChange: onChangeTimePicker('sunday_start_break'),
            value: formData.sunday_start_break.value
        },
        sunday_end_break: {
            name: 'sunday_end_break',
            onChange: onChangeTimePicker('sunday_end_break'),
            value: formData.sunday_end_break.value
        }
        // first_name: {
        //     name: 'first_name',
        //     placeholder: 'ADDSERVICE_P_FIRSTNAME',
        //     inputValidator: isValid,
        //     required: true,
        //     value: formData.first_name.value
        // },
        // middle_name: {
        //     name: 'middle_name',
        //     placeholder: 'ADDSERVICE_P_MIDDLENAME',
        //     value: formData.middle_name.value
        // },
        // last_name: {
        //     name: 'last_name',
        //     placeholder: 'ADDSERVICE_P_LASTNAME',
        //     value: formData.last_name.value
        // },
        // profession: {
        //     name: 'profession',
        //     placeholder: 'ADDSERVICE_P_PROFESSION',
        //     value: formData.profession.value
        // },
        // service: {
        //     name: 'service',
        //     placeholder: 'ADDSERVICE_P_SERVICE',
        //     value: formData.service.value
        // },
        // phone_mobile: {
        //     name: 'phone_mobile',
        //     placeholder: 'ADDSERVICE_P_PHONE',
        //     inputValidator: isOnlyNumbersWithPlus,
        //     value: formData.phone_mobile.value
        // },
        // phone_home: {
        //     name: 'phone_home',
        //     placeholder: 'ADDSERVICE_P_PHONE',
        //     inputValidator: isOnlyNumbersWithPlus,
        //     value: formData.phone_home.value
        // },
        // email: {
        //     name: 'email',
        //     placeholder: 'ADDSERVICE_P_WORKEMAIL',
        //     inputValidator: isEmail,
        //     value: formData.email.value,
        //     required: true
        // },
        // home_address_line1: {
        //     name: 'home_address_line1',
        //     placeholder: 'ADDSERVICE_P_WORKADDRESS',
        //     value: formData.home_address_line1.value
        // },
        // home_zipcode: {
        //     name: 'home_zipcode',
        //     placeholder: 'ADDSERVICE_P_ZIP',
        //     value: formData.home_zipcode.value
        // }
    };
};
