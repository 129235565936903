import { lazy } from 'react';

export const LoginContainer = lazy(() => import('containers/Login/LoginContainer'));
export const RegistrationContainer = lazy(() => import('containers/Registration/RegistrationContainer'));

export const HomeContainer = lazy(() => import('containers/Home/Home'));
export const AboutContainer = lazy(() => import('containers/About/AboutContainer'));
export const ContactContainer = lazy(() => import('containers/Contact/ContactContainer'));

export const ServicesContainer = lazy(() => import('containers/Services/ServicesContainer'));
export const ServiceFormContainer = lazy(() => import('containers/ServiceForm/ServiceFormContainer'));
export const ServiceContainer = lazy(() => import('containers/Service/ServiceContainer'));

export const PageNotFoundContainer = lazy(() => import('containers/PageNotFound/PageNotFoundContainer'));

export const TestingContainer = lazy(() => import('containers/_Testing/TestingContainer'));
