import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import Button from 'creatella-react-components/lib/Button';
import './AppHeaderNavSideNav.scss';

export default class AppHeaderNavSideNav extends PureComponent {
    static propTypes = {
        pathname: PropTypes.string.isRequired,
        navItems: PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired
    };

    renderNavItem = ({ id, label, route, classPrefix, onClick }) => {
        const { pathname } = this.props;
        const isActive = pathname === route;

        return (
            <Button
                key={id}
                className={`
                    AppHeaderNavSideNav__nav-button
                    AppHeaderNavSideNav__nav-button--${classPrefix}
                    ${isActive ? `AppHeaderNavSideNav__nav-button--${classPrefix}--isActive` : ''}
                `}
                onClick={onClick}
                label={label}
                route={route} />
        );
    };

    render() {
        const { navItems, onClose, isOpen } = this.props;

        return createPortal(
            <>
                <div
                    className={`AppHeaderNavSideNavBackdrop ${isOpen ? 'AppHeaderNavSideNavBackdrop--isOpen' : ''}`}
                    onClick={onClose} />

                <div className={`AppHeaderNavSideNav ${isOpen ? 'AppHeaderNavSideNav--isOpen' : ''}`}>
                    <div className='AppHeaderNavSideNav__nav'>
                        {navItems.map(this.renderNavItem)}
                    </div>
                </div>
            </>,
            document.body
        );
    }
}
