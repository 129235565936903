// import i18next from 'i18next';
import {
    getAppLanguage as getAppLanguageCache,
    setAppLanguage as setAppLanguageCache
} from 'utils/helpers';

const I18N_SET_LANGUAGE = 'I18N_SET_LANGUAGE';

export function setAppLanguage(language) {
    return {
        type: I18N_SET_LANGUAGE,
        language
    };
}

const initialState = {
    language: getAppLanguageCache()
};

export default function i18n(state = initialState, action) {
    switch (action.type) {
        case I18N_SET_LANGUAGE: {
            const { language } = action;

            setAppLanguageCache(language);

            // TODO: To use i18n then import i18next package first
            // i18next.changeLanguage(language);

            return {
                ...state,
                language
            };
        }

        default:
            return state;
    }
}
