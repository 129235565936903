/* eslint-disable react/jsx-max-props-per-line */

import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import AppLoading from '../Loading/AppLoading';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import { AuthedRoute, UnauthedRoute } from './components/RouteWrappers';
import {
    LoginContainer, RegistrationContainer, AboutContainer, ContactContainer,
    ServicesContainer, ServiceFormContainer, ServiceContainer,
    TestingContainer, PageNotFoundContainer, HomeContainer
} from './config/routes';
import EditServiceContainer from 'containers/EditService/EditServiceContainer';

// TODO_REPLACE(
//  Route: accessible for anyone
//  UnauthedRoute: accessible for unauthed clients only
//  AuthedRoute: accessible for authed clients only
// )

export default class AppRouter extends Component {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired
    };

    shouldComponentUpdate(nextProps) {
        const { isAuthed } = this.props;

        if (isAuthed !== nextProps.isAuthed) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed } = this.props;

        return (
            <Suspense fallback={<AppLoading />}>
                <Switch>
                    <UnauthedRoute path={ROUTES.LOGIN} exact={true} component={LoginContainer} isAuthed={isAuthed} />
                    <UnauthedRoute path={ROUTES.REGISTER} exact={true} component={RegistrationContainer} isAuthed={isAuthed} />

                    <Route path={ROUTES.HOME} exact={true} component={HomeContainer} />
                    <Route path={ROUTES.ABOUT} exact={true} component={AboutContainer} />
                    <Route path={ROUTES.CONTACT} exact={true} component={ContactContainer} />
                    <Route path={ROUTES.SERVICES} exact={true} component={ServicesContainer} />
                    <Route path={ROUTES.SERVICES_BY_CITY()} exact={true} component={ServicesContainer} />
                    <Route path={ROUTES.AXYN_SERVICES} exact={true} component={ServicesContainer} />
                    <Route path={ROUTES.AXYN_SERVICES_BY_CITY()} exact={true} component={ServicesContainer} />
                    <Route path={ROUTES.AXYN_WITH_EMAIL()} exact={true} component={ServicesContainer} />
                    <Route path={ROUTES.AXYN_WITH_EMAIL_CITY()} exact={true} component={ServicesContainer} />
                    <AuthedRoute isAuthed={isAuthed} path={ROUTES.ADD_SERVICE} exact={true} component={ServiceFormContainer} />
                    <Route path={ROUTES.EDIT_SERVICE()} exact={true} component={EditServiceContainer} />
                    <Route path={ROUTES.SERVICE()} exact={true} component={ServiceContainer} />

                    <Route path={ROUTES._TESTING} exact={true} component={TestingContainer} />

                    <Route component={PageNotFoundContainer} />
                </Switch>
            </Suspense>
        );
    }
}
