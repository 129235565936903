import { ELOQUENT } from 'config/constants';

/**
 * Search services
 * @param {Object} data
 * @returns {Promise}
 */
export function searchServices(data) {
    return ELOQUENT.Provider.getAllCustom(data);
}

//* to be checked first!!
export function searchServicesWithMetrics(data) {
    return ELOQUENT.Provider.getAll(data);
}

export function getServicesByPostalCodes() {
    return ELOQUENT.User.getUserWithPostalCodeServices();
}

export function createProviderFromSireneFile(file) {
    return ELOQUENT.SireneProvider.createOne(file);
}

export function createServiceProviderFromExcelFile(file) {
    return ELOQUENT.Provider.uploadExcelFile(file);
}

export function updateSireneKey(key) {
    return ELOQUENT.SireneProvider.getSireneResponse(key);
}

/**
 * Add serviceadd
 * @param {Object} data
 * @returns {Promise}
 */
export function addService(data) {
    return ELOQUENT.Provider.createOne(data);
}

/**
 * Add service
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateService(id, data) {
    return ELOQUENT.Provider.updateOne(id, data);
}

/**
 * Get a service by id
 * @param {Number} serviceId
 * @returns {Promise}
 */
export function getServiceById(serviceId) {
    return ELOQUENT.Provider.getOne(serviceId, {
        include: ['category', 'locations', 'media', 'subCategory ', 'subSubCategories', 'owner']
    });
}

/**
 * Get services by segment classification
 * @param {String} segment
 * @returns {Promise}
 */
export function getServicesBySegment(segment) {
    return ELOQUENT.Provider.getAllBySegment(segment);
}
