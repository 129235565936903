import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from 'creatella-react-components/lib/Button';
import './AppHeaderNav.scss';
import { ROUTES, STORAGEKEY_AXYN } from 'config/constants';
import AppHeaderNavSideNav from './components/SideNav/AppHeaderNavSideNav';
// ASSETS
import IMAGE_LOGO from 'assets/logo.svg';
import ItemPickerView from 'creatella-react-components/lib/ItemPickerView';
import { languages } from 'config/executors/i18n';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { handleLogout } from 'redux/reducers/auth';

class AppHeaderNav extends PureComponent {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired,
        pathname: PropTypes.string.isRequired,
        setAppLanguage: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.NAV_ITEMS = [
            {
                id: 1,
                label: this.props.t('COMMON_SERVICES'),
                route: ROUTES.SERVICES,
                classPrefix: 'text'
            }, {
                id: 2,
                label: this.props.t('COMMON_ABOUTUS'),
                route: ROUTES.HOME,
                onClick: () => sessionStorage.setItem('scrollToAbout', true),
                classPrefix: 'text'
            }
        ];

        if (props.isAuthed) {
            this.NAV_ITEMS.push({
                id: 3,
                label: this.props.t('COMMON_ADDYOURSERVICE'),
                route: ROUTES.ADD_SERVICE,
                classPrefix: 'text'
            },
            {
                id: 4,
                label: this.props.t('LOGIN_SCREEN_LOGOUT'),
                // route: '',
                onClick: () => this.props.dispatch(handleLogout()),
                classPrefix: 'text'
            });
        } else {
            this.NAV_ITEMS.push({
                id: 3,
                label: this.props.t('LOGIN_SCREEN_LOGIN'),
                route: ROUTES.LOGIN,
                classPrefix: 'text'
            });
        }

        this.state = {
            isSideNavOpen: false
        };
    }

    async componentDidMount() {
        this.updateServicesRoute();
    }

    async componentDidUpdate() {
        this.updateServicesRoute();
    }

    updateServicesRoute = async () => {
        const axynKey = await JSON.parse(localStorage.getItem(STORAGEKEY_AXYN));

        if (axynKey) {
            this.NAV_ITEMS[0].route = ROUTES.AXYN_SERVICES;
        } else {
            this.NAV_ITEMS[0].route = ROUTES.SERVICES;
        }
    };

    onOpenSideNav = () => {
        this.setState({ isSideNavOpen: true });
    };

    onCloseSideNav = () => {
        this.setState({ isSideNavOpen: false });
    };

    renderNavItem = ({ id, label, route, classPrefix, onClick }) => {
        const { pathname } = this.props;
        const isActive = pathname === route;

        return (
            <Button
                key={id}
                className={`
                    AppHeaderNav__nav-button
                    AppHeaderNav__nav-button--${classPrefix}
                    ${isActive ? `AppHeaderNav__nav-button--${classPrefix}--isActive` : ''}
                `}
                label={label}
                onClick={onClick}
                route={route} />
        );
    };

    onChangeItemPickerView = (name, value) => {
        this.props.setAppLanguage(value.key);
        window.location.reload();
    };

    render() {
        const { pathname } = this.props;
        const { isSideNavOpen } = this.state;

        return (
            <div className='AppHeaderNav'>
                <Button
                    className='AppHeaderNav__logo'
                    route={ROUTES.HOME}>
                    <img
                        className='AppHeaderNav__logo-img'
                        src={IMAGE_LOGO}
                        alt='HoaOra' />
                </Button>
                <ItemPickerView
                    className='AppHeaderNav__lang'
                    items={languages}
                    onChange={this.onChangeItemPickerView}
                    isVisible={true}
                    isToggle={true}
                    value={languages.find(l => l.key === this.props.language).id} />

                <div className='AppHeaderNav__nav'>
                    {this.NAV_ITEMS.map(this.renderNavItem)}

                    <Button
                        className='AppHeaderNav__nav-button AppHeaderNav__nav-button--menu'
                        onClick={isSideNavOpen ? this.onCloseSideNav : this.onOpenSideNav}>
                        {isSideNavOpen
                            ? <FontAwesomeIcon icon={faTimes} />
                            : <FontAwesomeIcon icon={faBars} />}
                    </Button>

                </div>

                <AppHeaderNavSideNav
                    pathname={pathname}
                    navItems={this.NAV_ITEMS}
                    onClose={this.onCloseSideNav}
                    isOpen={isSideNavOpen} />
            </div>
        );
    }
}

export default connect()(withTranslation()(AppHeaderNav));
