import { ELOQUENT } from 'config/constants';

/**
 * Get all categories
 * @returns {Promise}
 */
export function getCategories() {
    return ELOQUENT.Category.getAll();
}

/**
 * Get all sub categories
 * @returns {Promise}
 */
export function getSubCategories() {
    return ELOQUENT.SubCategory.getAll();
}

/**
 * Get all sub-sub categories
 * @returns {Promise}
 */
export function getSubSubCategories() {
    return ELOQUENT.SubSubCategory.getAll();
}

/**
 * Get all sub category for a category_id
 * @param {Number} categoryId
 * @returns {Promise}
 */
export function getSubByCategoryId(categoryId) {
    return ELOQUENT.SubCategory.getByCategoryID({ category_id: categoryId });
}

/**
 * Get all sub sub category for a sub_category_id
 * @param {Number} subCategoryId
 * @returns {Promise}
 */
export function getSubSubBySubCategoryID(subCategoryId) {
    return ELOQUENT.SubSubCategory.getBySubCategoryID({ sub_category_id: subCategoryId });
}
