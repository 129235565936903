import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from 'creatella-react-components/lib/ErrorBoundary';
import Helmet from 'creatella-react-components/lib/Helmet';
import Alerts, { ALERTS_POSITION } from 'creatella-react-components/lib/Alerts';
import { handleAutoLogin } from 'redux/reducers/auth';
import HELMET_DEFAULT_CONFIG from 'config/executors/i18n-resources/helmet';
import AppRouter from './components/Router/AppRouter';
import AppLoading from './components/Loading/AppLoading';
import AppHeaderNav from './components/HeaderNav/AppHeaderNav';
import './AppContainer.scss';
import { setAppLanguage } from 'redux/reducers/i18n';
import { ROUTES, STORAGEKEY_AXYN } from 'config/constants';

const routesToCheck = ['/', '/login', '/register'];

class AppContainer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        autoLogin: PropTypes.func.isRequired,
        isAppReady: PropTypes.bool.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        setAppLanguage: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { autoLogin, location: { pathname } } = this.props;

        if (pathname.includes(ROUTES.AXYN)) {
            localStorage.setItem(STORAGEKEY_AXYN, 1);
        }

        if (pathname.includes(ROUTES.SERVICES)) {
            localStorage.removeItem(STORAGEKEY_AXYN);
        }

        autoLogin();
    }

    shouldComponentUpdate(nextProps) {
        const { location, isAppReady, isAuthed, language } = this.props;
        const { pathname } = location;

        if (pathname !== nextProps.location.pathname ||
            isAppReady !== nextProps.isAppReady ||
            isAuthed !== nextProps.isAuthed ||
            language !== nextProps.language) {
            return true;
        }

        return false;
    }

    onError = (error, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras('Info', info);
            Sentry.captureException(error);
        });
    };

    render() {
        const { isAppReady, location, isAuthed, language, setAppLanguage } = this.props;
        const { pathname } = location;

        if (isAppReady) {
            return (
                <main
                    className='AppContainer'
                    style={{
                        paddingTop: !routesToCheck.includes(pathname) && 80
                    }}>
                    {
                        !routesToCheck.includes(pathname) && (
                            <AppHeaderNav
                                isAuthed={isAuthed}
                                pathname={pathname}
                                language={language}
                                setAppLanguage={setAppLanguage} />
                        )
                    }
                    <ErrorBoundary
                        pathname={pathname}
                        onError={this.onError}>
                        <AppRouter
                            isAuthed={isAuthed} />
                    </ErrorBoundary>

                    <Helmet
                        pathname={pathname}
                        defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                    <Alerts
                        position={ALERTS_POSITION.BOTTOM_LEFT} />
                </main>
            );
        }

        return (
            <main className='AppContainer'>
                <Helmet
                    pathname={pathname}
                    defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                <AppLoading />
            </main>
        );
    }
}

function mapStateToProps({ auth, i18n }) {
    const { isAppReady, isAuthed } = auth;
    const { language } = i18n;

    return {
        isAppReady,
        isAuthed,
        language
    };
}

function mapDispatchToProps(dispatch) {
    return {
        autoLogin: (location) => {
            dispatch(handleAutoLogin(location));
        },
        setAppLanguage: (language) => {
            dispatch(setAppLanguage(language));
        }
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppContainer)
);
