import { formatInTimeZone } from 'utils/helpers';

export const prepareDataToSend = (data) => {
    const newData = {};

    Object.keys(data).forEach((key) => {
        newData[key] = data[key].value;
    });

    newData.work_address = {
        address_line1: newData.work_address_line1,
        city: newData.work_city,
        country: newData.work_country,
        zipcode: newData.work_zipcode
    };

    newData.home_address = {
        address_line1: newData.home_address_line1,
        zipcode: newData.home_zipcode
    };

    const pictures = [];

    newData.picture1 && pictures.push(newData.picture1);
    newData.picture2 && pictures.push(newData.picture2);
    newData.picture3 && pictures.push(newData.picture3);

    newData.image = pictures;
    const videos = [];

    newData.video1 && videos.push(newData.video1);
    newData.video2 && videos.push(newData.video2);
    newData.video3 && videos.push(newData.video3);

    newData.video = videos;
    const documents = [];

    newData.document1 && documents.push(newData.document1);
    newData.document2 && documents.push(newData.document2);
    newData.document3 && documents.push(newData.document3);

    newData.document = documents;

    delete newData.picture1;
    delete newData.picture2;
    delete newData.picture3;

    delete newData.video1;
    delete newData.video2;
    delete newData.video3;

    delete newData.document1;
    delete newData.document2;
    delete newData.document3;

    // format timings
    const timings = [
        getTiming('Monday'),
        getTiming('Tuesday'),
        getTiming('Wednesday'),
        getTiming('Thursday'),
        getTiming('Friday'),
        getTiming('Saturday'),
        getTiming('Sunday')
    ];

    newData.timings = timings;

    newData.url = [newData.url];

    deleteTiming('Monday');
    deleteTiming('Tuesday');
    deleteTiming('Wednesday');
    deleteTiming('Thursday');
    deleteTiming('Friday');
    deleteTiming('Saturday');
    deleteTiming('Sunday');

    newData.type = 1;

    newData.sub_category_id = data.sub_category;
    newData.subSubCategories = data.sub_sub_category.value;

    return newData;

    function getTiming(day) {
        const _day = day.toLowerCase();
        const start = `${_day}_start`;
        const end = `${_day}_end`;
        const startBreak = `${_day}_start_break`;
        const endBreak = `${_day}_end_break`;

        const toUTC = (time) => {
            time = `${time}:00`;
            const date = (new Date()).toISOString();

            const newDate = date.slice(0, 11) + time;

            return formatInTimeZone(newDate, 'HH:mm:ss', 'UTC');
        };

        return {
            day,
            open_at: data[start].value ? toUTC(data[start].value) : null,
            close_at: data[end].value ? toUTC(data[end].value) : null,
            break_from: data[startBreak].value ? toUTC(data[startBreak].value) : null,
            break_to: data[endBreak].value ? toUTC(data[endBreak].value) : null,
            status: data[start].value && data[end].value ? 2 : 1
        };
    }

    function deleteTiming(day) {
        const _day = day.toLowerCase();
        const start = `${_day}_start`;
        const end = `${_day}_end`;
        const startBreak = `${_day}_start_break`;
        const endBreak = `${_day}_end_break`;

        delete newData[start];
        delete newData[end];
        delete newData[startBreak];
        delete newData[endBreak];
    }
};
