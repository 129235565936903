import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'react-time-picker/dist/entry.nostyle';
import './TimePicker.scss';
import classNames from 'classnames';

export default function Picker(props) {
    const classes = classNames('time_picker', { 'time_picker-invalid': props.isInvalid });

    return <div className={classes}>
        <TimePicker
            {...props} />
    </div>;
}

Picker.propTypes = {
    isInvalid: PropTypes.bool
};
